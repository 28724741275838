// styles
import "./AboutPage.css";

// assets
import theyMineForYou from "../../assets/images/about/Group 20.png";
import claimBalance from "../../assets/images/about/Group 21.png";
import coin from "../../assets/images/about/Group.png";
import coinTop from "../../assets/images/about/coin-top.png";
import coinBottom from "../../assets/images/about/coin-bottom.png";
import { useMediaQuery } from "react-responsive";

const AboutPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="about">
      <section className="bg-[#6A5637] pt-44 md:pt-[176px]">
        <div className="max-w-[1362px] mx-auto px-4 md:px-0 flex flex-col items-center justify-center">
          <h1 className="text-white mb-5 text-4xl md:text-[64px] leading-tight md:leading-[96px] text-center">Dino Bitis</h1>
          <p className="max-w-[1088px] text-white text-sm md:text-base leading-8 md:leading-[35.2px] text-center mt-4 md:mt-0">
            Dino Bitis are motion NFTs that pay you as you hold them. They are
            Digital-World-Assets that increase and decrease in value just like
            real-world-assets. Each one is a 1 of 1.
          </p>
        </div>
        <div className="relative pt-14 pb-4 md:pt-28 md:pb-16 overflow-hidden">
          <div className="flex flex-col gap-6">
            <div className="grid grid-flow-col grid-cols-[repeat(7,159px)] sm:grid-cols-[repeat(7,253px)] items-center gap-6">
              {Array(7)
                .fill(null)
                .map((_, index) => (
                  <div className="w-full h-[160px] sm:h-[254px]">
                    <img
                      src={`/videos/fake_dinos/site ${index + 1}.gif`}
                      alt={`fake dino ${index + 1}`}
                      className="w-full h-full"
                    />
                  </div>
                ))}
            </div>
            <div className="grid grid-flow-col grid-cols-[repeat(7,159px)] sm:grid-cols-[repeat(7,253px)] items-center gap-6">
              {Array(7)
                .fill(null)
                .map((_, index) => (
                  <div className="w-full h-[160px] sm:h-[254px]">
                    <img
                      src={`/videos/fake_dinos/site ${index + 8}.gif`}
                      alt={`fake dino ${index + 8}`}
                      className="w-full h-full"
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="relative w-full py-5 h-[132px]">
            <div className="absolute right-1/4">
              <img src={theyMineForYou} alt="they mine for you" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-[#3F3A33] py-12 md:py-24 overflow-x-clip">
        <div className="max-w-[976px] mx-auto px-4 md:px-0 flex flex-col items-center justify-center">
          <h2 className="text-white text-3xl md:text-[48px] leading-snug md:leading-[72px] text-center">
            How to get your own 1 of 1 Dino Biti?
          </h2>
        </div>
        <div className="max-w-6xl mx-auto px-4 md:px-0 py-10 md:py-20">
          <div className="relative flex flex-col md:flex-row items-start justify-center w-full">
            <div className="w-full md:w-1/2">
              <img src={claimBalance} alt="clain balance" className="w-full" />
            </div>
            <div className="w-full md:w-1/2 pt-6 px-4 md:px-0 md:pt-6 flex items-center justify-center">
              <div className="max-w-[460px] text-[#A1A1A1] text-sm md:text-base leading-6 md:leading-8 flex flex-col gap-y-6">
                <p>
                  You must have at least{" "}
                  <b className="text-white">2,100,000 BYAS</b> to mint a Dino
                  Biti
                </p>
                <p>
                  You must have at least{" "}
                  <b className="text-white">1,000,000 HUNDRED</b> in your wallet
                  to mint a Dino Biti
                </p>
                <p>If you want a more valuable Dino Biti use more BYAS</p>
                <p>
                  If you happen to mint a rare Dino Biti you can then flip it in
                  the marketplace for instant profit.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="relative max-w-6xl mx-auto py-6 md:py-12 px-4 md:px-0">
          <div className="absolute w-full h-full">
            <div className="absolute hidden md:block z-[5] -top-[calc(178.93px_/_2)] -right-[calc(191.71px_/_2)]">
              <img src={coinTop} alt="coin icon" />
            </div>
            <div className="absolute hidden md:block z-[5] -bottom-[calc(178.93px_/_2)] -left-[calc(191.71px_/_2)]">
              <img src={coinBottom} alt="coin icon" />
            </div>
          </div>
          <div className="background min-h-[666px] py-12">
            <div className="max-w-[748px] mx-auto flex flex-col items-center justify-center px-4 md:px-0">
              <h3 className="text-white text-2xl md:text-4xl leading-snug md:leading-[54px] text-center">
                Your passive income
              </h3>
              <p className="text-white text-sm md:text-base leading-6 md:leading-8 text-center mt-4">
                Enjoy the passive income and value increase that a Dino Biti can
                provide as more people use the BYAS network.
              </p>
            </div>
            <div className="max-w-[856px] mt-10 md:mt-14 mx-auto px-4 md:px-0">
              <h4 className="text-white text-xl md:text-2xl leading-snug md:leading-9 text-center mb-6 md:mb-8">
                You'll receive income
              </h4>
              <div className="flex flex-col gap-4 md:gap-6">
                <div className="frame-69-item py-2.5 px-5 grid grid-cols-[44px,auto] items-center gap-2 md:gap-4 rounded-3xl md:rounded-full">
                  <div className="flex items-center justify-center">
                    <img src={coin} alt="coin icon" className="w-8 h-8 md:w-auto md:h-auto" />
                  </div>
                  <p className="text-white text-base md:text-lg leading-snug md:leading-[27px]">
                    every time someone transacts in BYAS
                  </p>
                </div>
                <div className="frame-69-item py-2.5 px-5 grid grid-cols-[44px,auto] items-center gap-2 md:gap-4 rounded-3xl md:rounded-full">
                  <div className="flex items-center justify-center">
                    <img src={coin} alt="coin icon" className="w-8 h-8 md:w-auto md:h-auto" />
                  </div>
                  <p className="text-white text-base md:text-lg leading-snug md:leading-[27px]">
                    every time someone mints a new Dino Biti
                  </p>
                </div>
                <div className="frame-69-item py-2.5 px-5 grid grid-cols-[44px,auto] items-center gap-2 md:gap-4 rounded-3xl md:rounded-full">
                  <div className="flex items-center justify-center">
                    <img src={coin} alt="coin icon" className="w-8 h-8 md:w-auto md:h-auto" />
                  </div>
                  <p className="text-white text-base md:text-lg leading-snug md:leading-[27px]">
                    you own a piece of everything that happens and will happen
                    in the BYAS ecosystem
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
