// utils.js using CommonJS syntax
const ethers = require('ethers');

const backendUrl = process.env.REACT_APP_BACKEND_URL
// const backendUrl = "http://localhost:5000"

function normalizeAddress(address) {
  return address ? address.toLowerCase() : '';
}

function toChecksumAddress(address) {
  return ethers.getAddress(address);
}

module.exports = { normalizeAddress, toChecksumAddress, backendUrl};
