// react
import { useState } from "react";
// imports
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { Dialog } from "@headlessui/react";
import { useAccount, useWriteContract } from "wagmi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

// styles
import "./ListNftDialog.css";

// abis
import marketPlaceContractAbi from "../../../ABIs/marketplace-contract-abi.json";
import nftContractAddressAbi from "../../../ABIs/nftABI.json";
// hooks
import { useModals, useAppData } from "../../../hooks";
import { ethers } from "ethers";

// constants
const usdtContractAddress = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";
const marketplaceContractAddress = "0xf9BCb7225e8fc27231B495eB35c79F50cF15f4F0";
const nftContractAddress = "0x7bE8bA2250d9Ee765B5C23F546AA8Ef1b93B58d7";

const ListNftDialog = () => {
  // state
  const [showMetadta, setShowMetadata] = useState(false);
  const [allowance, setAllowance] = useState(null);

  // hooks
  const {
    data: { listNftModalOpen },
    setListNftModalStatus,
  } = useModals();
  const {
    data: { selectedNft },
  } = useAppData();
  const { chainId } = useAccount();

  // contract hooks
  const { writeContractAsync } = useWriteContract();

  // form hooks
  const { register, handleSubmit } = useForm();

  // derived data
  const attributes = selectedNft?.metadata
    ? JSON.parse(selectedNft.metadata).attributes ?? []
    : selectedNft.normalized_metadata.attributes;

  // handlers
  const closeModal = () => {
    setListNftModalStatus(false);
  };

  const toggleShowMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  const handleListNft = async (data, event) => {
    event?.preventDefault();
    console.log("handleListNft triggered");

    if (!selectedNft) return;

    try {
      console.log('Attempting to list NFT with token ID:', selectedNft.token_id, 'for price:', data.price);

      // Approving the marketplace to transfer the NFT
      console.log('Approving marketplace to transfer the NFT...');
        const approvalTx = await writeContractAsync({
          abi: nftContractAddressAbi,
          chainId: chainId,
          address: nftContractAddress,
          functionName: "approve",
          args: [marketplaceContractAddress, selectedNft.token_id],
        });

        // Introduce a delay to wait for approval to complete
      console.log('Waiting for approval to complete...');
      await new Promise((resolve) => setTimeout(resolve, 10000));

      const parsedPrice = ethers.parseEther(data.price);
      const txhash = await writeContractAsync({
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "createListing",
        args: [
          selectedNft.token_address,
          selectedNft.token_id,
          usdtContractAddress,
          parsedPrice,
        ],
      });

      closeModal();

      setTimeout(() => window.location.reload(), 6000);

    } catch (error) {
      console.error("handleBuyNft", error);
      //toast.error(`Failed to list NFT: ${error.message}`);//
    }
  };

  if (!listNftModalOpen || !selectedNft) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: listNftModalOpen ? 1 : 0,
        backdropFilter: listNftModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={listNftModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="w-full max-w-4xl transform overflow-hidden text-left align-middle"
          >
            <div className="w-full flex flex-col md:flex-row items-center">
              <div className="relative w-full md:w-2/5">
                <img
                  src={selectedNft.normalized_metadata.image}
                  alt={`${selectedNft.name} ${selectedNft.normalized_metadata.name}`}
                  width={512}
                  className="w-full h-auto"
                />
                {showMetadta && (
                  <div className="absolute top-0 w-full h-full bg-black/70 pt-11 pb-6 px-4">
                    <div className="w-full flex flex-col gap-y-3 text-white">
                      {attributes.map((attribute) => {
                        const key = Object.keys(attribute)[0];
                        return (
                          <div className="w-full flex flex-col">
                            <span className="opacity-50">{key}</span>
                            <div className="w-full flex flex-row items-center justify-between">
                              <span>{attribute[key]}</span>
                              <span>{attribute.value}%</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <button
                  onClick={toggleShowMetadata}
                  className="absolute top-2 right-2"
                >
                  <img src="/videos/nfts/info.png" alt="info" />
                </button>
              </div>
              <div className="w-full md:w-3/5 px-1 md:p-6 md:pl-0 text-white">
                <div className="nft-details-bg w-full py-5 px-8">
                  <form
                    onClick={handleSubmit(handleListNft)}
                    className="flex flex-col gap-5"
                  >
                    {/* content */}
                    <div className="flex flex-col gap-3">
                      <Dialog.Title as="h3" className="text-base md:text-xl">
                        {" "}
                        {selectedNft.normalized_metadata.name}
                      </Dialog.Title>
                      <div className="bg-[#A3834E] h-16 px-4 flex items-center justify-between gap-2.5">
                        <input
                          {...register("price", {
                            required: true,
                            validate: (value) => {
                              const parsedValue = Number(value);

                              // check if the amount is a valid number
                              if (isNaN(parsedValue)) {
                                return "Invalid amount";
                              }

                              // check if the amount is greater than 0
                              if (parsedValue <= 0) {
                                return "Amount must be greater than 0";
                              }

                              // return true if the value is valid
                              return true;
                            },
                          })}
                          placeholder="Price"
                          className="bg-transparent w-full py-2 text-white/60 placeholder:text-white/60 text-sm md:text-base focus:outline-none"
                        />
                        <h4 className="text-base md:text-xl">USDT</h4>
                      </div>
                    </div>
                    {/* button */}
                    <div>
                      <button
                        type="submit"
                        className="bg-[#FCE87F] min-h-12 pt-0.5 px-6 flex items-center justify-center gap-2.5"
                      >
                        <span className="text-sm md:text-base text-[#BE9651] leading-5">
                          LIST
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default ListNftDialog;
