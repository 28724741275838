function copyToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  
  // Make the textarea out of viewport
  textarea.style.position = 'fixed';
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.opacity = '0';
  
  document.body.appendChild(textarea);
  
  textarea.focus();
  textarea.select();
  
  try {
    document.execCommand('copy');
    console.log('Text copied to clipboard');
    return true;
  } catch (error) {
    console.error('Failed to copy text to clipboard', error);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
}

export default copyToClipboard;