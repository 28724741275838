// src/components/Header/Header.jsx

// imports
import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
// imports
import { useAccount } from "wagmi";
import { Link, useLocation } from "react-router-dom";
import { getUserData } from "../MiningPage";
import { putUserProfilePic } from "../MiningPage";
import { useMediaQuery } from "react-responsive";

// styles
import "./Header.css";

// images
import Logo from "../../assets/images/themes/gold/buttons/Logo.png"; // path to your text image
import textImage1 from "../../assets/images/themes/gold/buttons/Mining.png"; // path to your text image
import textImage2 from "../../assets/images/themes/gold/buttons/About.png"; // path to your text image
import textImage3 from "../../assets/images/themes/gold/buttons/Marketheader.png"; // path to your text image
import textImage4 from "../../assets/images/themes/gold/buttons/Gallery1.png"; // path to your text image
import coin from "../../assets/images/about/Group.png"; // path to your text image
import dollar from "../../assets/images/about/dollar.png"; // path to your text image
import blank from "../../assets/images/about/blank.png"; // path to your text image
import menuImage from "../../assets/images/themes/gold/buttons/hamburger.png";
import ConnectButton from "../WalletConnect/ConnectButton";
import connectButtonImage from "../../assets/images/themes/gold/buttons/Connect.png";
import disconnectButtonImage from "../../assets/images/themes/gold/buttons/Disconnect.png";
import defaultUserProfileAvatar from "../../assets/images/Avatar.png";

// hooks
import { useModals } from "../../hooks";
import { backendUrl } from "../utils";

const Header = () => {
  // router hooks
  const location = useLocation();
  // wagmi hooks
  const { isConnected } = useAccount();
  // modals hooks
  const {
    setBurnTokensModalStatus,
    setSendTokensModalStatus,
    setReceiveTokensModalStatus,
  } = useModals();

  // determine if the current page is the mining page
  const currentPath = location.pathname;
  const isMiningPage = currentPath === "/mining";

  // handlers
  const openSendTokensModal = () => {
    setSendTokensModalStatus(true);
  };

  const openReceiveTokensModal = () => {
    setReceiveTokensModalStatus(true);
  };

  const openBurnTokensModal = () => {
    setBurnTokensModalStatus(true);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [userAddress, setUserAddress] = useState(null);
  const { address } = useAccount();
  const [profilePicUrl, setProfilePicUrl] = useState(defaultUserProfileAvatar); // Initialize with default avatar

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (address) {
      setUserAddress(address);
      console.log("User Address Set:", address);

      // Fetch and set the profile picture URL, if available
      const fetchProfilePic = async () => {
        if (address) {
          const userData = await getUserData(address);
          if (userData && userData.profilePic) {
            setProfilePicUrl(userData.profilePic);
          } else {
            setProfilePicUrl(defaultUserProfileAvatar); // Use default avatar if no profile pic
          }
        }
      };

      fetchProfilePic();
    }
  }, [address]);

  const triggerFileInput = () => {
    // Trigger the hidden file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePic", file);

      console.log("Attempting to upload file for address:", userAddress);

      try {
        const response = await axios.post(
          `${backendUrl}/api/user/${userAddress}/uploadProfilePic`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("File uploaded:", response.data);
        closePopup();

        const profilePicUrl = response.data.profilePicUrl;
        setProfilePicUrl(profilePicUrl);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (e) => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header-container">
        <nav>
          <a href="https://byas.app" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="BYAS" />
          </a>
          {isMobile && isConnected &&  (
            <div className="header-userbar-profile ml-auto mr-10">
              <img
                src={profilePicUrl}
                alt="user avatar"
                onClick={triggerFileInput}
                style={{width: 45}}
              />
              {/* File Input (hidden) */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                accept=".jpg, .jpeg, .png" // Accept JPEG and PNG files only
              />
              {/* Popup for uploading profile picture */}
              {isPopupOpen && (
                <div className="popup">
                  <button onClick={triggerFileInput}></button>
                  <button onClick={closePopup}>Close</button>
                </div>
              )}
            </div>
          )}
          {isMobile ? (
            <div className="mobile-header" onClick={toggleMenu}>
              <button onClick={toggleMenu} className="hamburger-menu">
                {/* Replace with your hamburger icon image */}
                <img
                  src={menuImage}
                  alt="Menu"
                  style={{ minWidth: 30, height: 30 }}
                />
              </button>
              <div
                className={`mobile-nav ${isMenuOpen ? "show-mobile-nav" : ""}`}
              >
                {/* Navigation links go here */}
                <ul>
                  {/* <li>
                  <a
                    href="https://byas.app"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Logo} alt="BYAS" />
                  </a>
                </li> */}
                  <li>
                    <Link to="/">
                      <div className="nav-link-mobile">
                        <img src={blank} className="icon" alt="icon" />
                        <span className="ms-2">Home</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <div className="nav-link-mobile">
                        <img src={blank} className="icon" alt="icon" />
                        <span className="ms-2">About</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/mining">
                      <div className="nav-link-mobile">
                        <img src={coin} className="icon" alt="icon" />
                        <span className="ms-2">Mining</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <div className="nav-link-mobile">
                        <img src={blank} className="icon" alt="icon" />
                        <span className="ms-2">Gallery</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/nft">
                      <div className="nav-link-mobile">
                        <img src={dollar} className="icon" alt="icon" />
                        <span className="ms-2">Marketplace</span>
                      </div>
                    </Link>
                  </li>
                  {isMiningPage && isConnected ? (
                    <></>
                  ) : (
                    <ConnectButton
                      imageSrc={{
                        connectedImage: disconnectButtonImage,
                        disconnectedImage: connectButtonImage,
                      }}
                      customClass="header-connect-button pl-[22px] mt-5"
                    />
                  )}
                </ul>
              </div>
            </div>
          ) : (
            // <nav>
            <>
              <Link to="/about">
                <img src={textImage2} alt="Mining" />
              </Link>
              <Link to="/mining">
                <img src={textImage1} alt="Mining" />
              </Link>
              <Link to="/gallery">
                <img src={textImage4} alt="Gallery" />
              </Link>
              <Link to="/nft">
                <img src={textImage3} alt="Marketplace" />
              </Link>
              {isMiningPage && isConnected ? (
                <div className="header-userbar">
                  <div
                    className="header-userbar-btn send"
                    onClick={openSendTokensModal}
                  >
                    <span>SEND</span>
                  </div>
                  <div
                    className="header-userbar-btn receive"
                    onClick={openReceiveTokensModal}
                  >
                    <span>RECEIVE</span>
                  </div>
                  <div
                    className="header-userbar-btn burn"
                    onClick={openBurnTokensModal}
                  >
                    <span>BURN</span>
                  </div>
                  <div className="header-userbar-profile">
                    <img
                      src={profilePicUrl}
                      alt="user avatar"
                      onClick={triggerFileInput}
                    />
                    {/* File Input (hidden) */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png" // Accept JPEG and PNG files only
                    />

                    {/* Popup for uploading profile picture */}
                    {isPopupOpen && (
                      <div className="popup">
                        <button onClick={triggerFileInput}></button>
                        <button onClick={closePopup}>Close</button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <ConnectButton
                  imageSrc={{
                    connectedImage: disconnectButtonImage,
                    disconnectedImage: connectButtonImage,
                  }}
                  customClass="header-connect-button"
                />
              )}
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
