// react
import { useContext } from "react";

// context
import { ModalsContext } from "../contexts";

const useModals = () => {
  const { data, setData } = useContext(ModalsContext);

  // wallet modals handlers
  const setBurnTokensModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      burnTokenModalOpen: status,
    }));
  };

  const setSendTokensModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      sendTokenModalOpen: status,
    }));
  }

  const setReceiveTokensModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      receiveTokenModalOpen: status,
    }));
  }

  // nft modals handlers
  const setBuyNftModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      buyNftModalOpen: status,
    }))
  }

  const setListNftModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      listNftModalOpen: status,
    }))
  }

  const setEditNftModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      editNftModalOpen: status,
    }))
  }

  const setClaimNftModalStatus = (status) => {
    setData((prevState) => ({
      ...prevState,
      claimNftModalOpen: status,
    }))
  }

  return {
    data,
    // wallet handlers
    setBurnTokensModalStatus,
    setSendTokensModalStatus,
    setReceiveTokensModalStatus,
    // nft hanlders
    setBuyNftModalStatus,
    setListNftModalStatus,
    setEditNftModalStatus,
    setClaimNftModalStatus,
  };
};

export default useModals;