import { BigNumber } from "bignumber.js";

export const formatNumberToFixed = (number, decimals = 2) => {
  const bigNum = new BigNumber(number);

  if (bigNum.isNaN()) {
    return "0";
  }

  return bigNum.toFixed(decimals);
}