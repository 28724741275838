// react
import { useRef, useState } from "react";
// imports
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import {
  useClient,
  useAccount,
  useWriteContract,
} from "wagmi";
import { readContract } from "viem/actions";

import { toast } from "react-toastify";

// styles
import "./BurnTokensDialog.css";

// abi
import burnContractAbi from "../../../ABIs/burn-contract-abi.json";

// components
import BurnTokenForm from "./BurnTokenForm";

// hooks
import { useModals } from "../../../hooks";

// data
import { burnableTokens } from "../../../data";

const burnContractAddress = "0xa31b358354f9D30c5949D1f2F47c98DCb7Fc41cd";

const BurnTokensDialog = () => {
  // ref
  const formRef = useRef(null);

  // state
  const [selectedToken, setSelectedToken] = useState(burnableTokens.at(0));

  // hooks
  const {
    data: { burnTokenModalOpen },
    setBurnTokensModalStatus,
  } = useModals();
  const { address, chainId } = useAccount();

  // contract hooks
  const client = useClient();
  const { writeContractAsync } = useWriteContract();

  // handlers
  const closeModal = () => {
    setBurnTokensModalStatus(false);
  };

  const handleSelectToken = (token) => {
    setSelectedToken(token);
  };

  const handleBurnTokens = async ({ amount }) => {
    const functionName = selectedToken.isNative ? "execute" : "burnERC20";
    const preparedArgs = selectedToken.isNative
      ? []
      : [selectedToken.address, amount];
    const value = selectedToken.isNative ? amount : 0;

    try {
      if (!selectedToken.isNative) {
        // get the erc20 token info
        const token = burnableTokens.find(
          (token) => token.address === selectedToken.address
        );

        // check the allowance
        const allowance = await readContract(client, {
          abi: token.abi,
          chainId: chainId,
          address: token.address,
          functionName: "allowance",
          args: [address, burnContractAddress],
        });

        if (allowance < amount) {
          // approve the burn contract
          await writeContractAsync({
            abi: token.abi,
            chainId: chainId,
            address: token.address,
            functionName: "approve",
            args: [burnContractAddress, amount],
          });
        }
      }

      const txhash = await writeContractAsync({
        abi: burnContractAbi,
        chainId: chainId,
        address: burnContractAddress,
        functionName,
        args: preparedArgs,
        value: value,
      });

      // Close the modal regardless of the user's action
      closeModal();
      
    } catch (error) {
      console.error(error);
      
    }
  };

  const handleFormSubmit = () => {
    formRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  if (!burnTokenModalOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: burnTokenModalOpen ? 1 : 0,
        backdropFilter: burnTokenModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={burnTokenModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="dialog-bg-burn w-full max-w-md transform overflow-hidden rounded-2xl text-white p-6 text-left align-middle flex flex-col items-center justify-center gap-5"
          >
            <Dialog.Title as="h3" className="text-xl md:text-2xl font-medium leading-6">
              BURN
            </Dialog.Title>

            {Boolean(selectedToken) && (
              <BurnTokenForm
                address={address}
                chainId={chainId}
                formRef={formRef}
                selectedToken={selectedToken}
                burnableTokens={burnableTokens}
                onBurnTokens={handleBurnTokens}
                onSelectToken={handleSelectToken}
              />
            )}

            <div className="mt-4">
              <button
                type="button"
                className="flex items-center justify-center bg-white min-w-64 h-8 px-6 py-2 text-sm font-medium text-[#9B8D47] focus:outline-none"
                onClick={handleFormSubmit}
              >
                Burn
              </button>
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="flex items-center justify-center bg-white min-w-64 h-8 px-6 py-2 text-sm font-medium text-[#9B8D47] focus:outline-none"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default BurnTokensDialog;
