// react
import { useState } from "react";
// imports
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { useForm } from "react-hook-form";
import { useBalance, useReadContract } from "wagmi";

import feedRegistryContractAbi from "../../../ABIs/feed-registry-abi.json";

// helpers
import { formatNumberToFixed } from "../../../helpers";

// constants
const feedRegistryContractAddress =
  "0x1647a10D50e1Ebf84FF6E38e4c8dd1298E0E69cC";

const BurnTokenForm = ({
  address,
  chainId,
  formRef,
  selectedToken,
  burnableTokens,
  onBurnTokens,
  onSelectToken,
}) => {
  // state
  const [dxoAmount, setDxoAmount] = useState();

  // contract hooks
  const { data: balance } = useBalance({
    address: address,
  });
  const { data: priceData } = useReadContract({
    abi: feedRegistryContractAbi,
    chainId: chainId,
    address: feedRegistryContractAddress,
    functionName: "latestAnswerByName",
    args: [selectedToken.symbol, "USD"],
  });
  const { data: tokenBalanceData } = useReadContract({
    chainId: chainId,
    abi: selectedToken.abi,
    address: selectedToken.address,
    functionName: "balanceOf",
    args: [address],
  });

  // form hooks
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // handlers
  const onSubmit = (data, event) => {
    event?.preventDefault();

    onBurnTokens({
      // convert the amount to wei if token is not native
      amount: ethers.parseUnits(data.amount, 18),
    });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const isNative = value === "BNB";

    if (isNative) {
      onSelectToken({
        name: "BNB",
        symbol: "BNB",
        isNative: true,
      });
    } else {
      const selectedToken = burnableTokens.find(
        (token) => token.symbol === event.target.value
      );

      onSelectToken({
        ...selectedToken,
        isNative: false,
      });
    }

    // reset dxo amount
    setDxoAmount(undefined)
  };

  const handleBurnAmountChange = (event) => {
    const value = Number(event.target.value);

    console.log(value);

    if (isNaN(value) || value <= 0 || !priceData) {
      return;
    }

    // calculations in wei
    const amount = ethers.parseUnits(value.toString(), 18);
    const rate = priceData;
    const fee1Amount = (amount * 5n) / 1000n;
    const fee2Amount = (amount * 3n) / 1000n;

    const newAmount = amount - fee1Amount - fee2Amount;
    const dxoAmount = (rate * newAmount) / 10n ** 8n;

    setDxoAmount(dxoAmount);
  };

  return (
    <div className="max-w-lg w-full mt-4">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-8">
          {/* amount */}
          <div className="bg-[#A3834E] w-full h-12 flex flex-row">
            <input
              id="amount"
              type="string"
              {...register("amount", {
                required: {
                  value: true,
                  message: "Amount is required",
                },
                validate: (value) => {
                  const parsedValue = Number(value);

                  // check if the amount is a valid number
                  if (isNaN(parsedValue)) {
                    return "Invalid amount";
                  }

                  // check if the amount is greater than 0
                  if (parsedValue <= 0) {
                    return "Amount must be greater than 0";
                  }

                  // check if the amount is less than the burners's balance
                  const parsedValueInWei = ethers.parseUnits(value, 18);
                  if (parsedValueInWei > selectedToken.balance) {
                    return "Insufficient balance";
                  }
                },
                onChange: (event) => {
                  handleBurnAmountChange(event);
                },
              })}
              placeholder="0.1"
              className="block w-full py-2 px-3 bg-transparent text-white/60 placeholder:text-white/60 focus:outline-none sm:text-sm"
            />
            <select
              onChange={handleSelectChange}
              value={selectedToken?.symbol}
              className="bg-transparent focus:outline-none"
            >
              {burnableTokens.map((token, index) => (
                <option key={index} value={token.symbol} className="text-black">
                  {token.symbol}
                </option>
              ))}
              <option value="BNB" className="text-black">
                BNB
              </option>
            </select>
          </div>
          {errors.amount && (
            <p className="text-red-500 text-xs">
              {errors.amount.message} {console.log(errors.amount)}
            </p>
          )}
          <div className="w-full flex flex-col gap-8 text-xs md:text-base">
            <div className="flex flex-row items-center justify-between">
              <span className="uppercase">Balance:</span>
              <span className="text-white">
                {!Boolean(selectedToken.isNative)
                  ? formatNumberToFixed(
                      ethers.formatEther(tokenBalanceData ?? 0n)
                    )
                  : formatNumberToFixed(
                      ethers.formatEther(balance?.value ?? 0n)
                    )}{" "}
                {selectedToken.symbol}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="uppercase">Price:</span>
              <span className="text-white">
                ${formatNumberToFixed(ethers.formatUnits(priceData ?? 0n, 8))}
              </span>
            </div>
            <div className="flex flex-row items-center justify-between">
              <span className="uppercase">Total Value:</span>
              <span className="text-white">
                ${formatNumberToFixed(ethers.formatEther(dxoAmount ?? 0n))}
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BurnTokenForm;
