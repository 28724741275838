// react
import { createContext } from "react";

// context
const AppContext = createContext({
  data: {},
  setData: (value) => {},
});

// provider
const AppProvider = AppContext.Provider;
// consumer
const AppConsumer = AppContext.Consumer;

export { AppProvider, AppConsumer };
export default AppContext;
