// react
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
// imports
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useAccount, useReadContracts, useWriteContract } from "wagmi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

// styles
import "./BuyNftDialog.css";

// abis
import usdtAbi from "../../../ABIs/usdt-abi.json";
import nftContractAbi from "../../../ABIs/nftABI.json";
import escrowContractAbi from "../../../ABIs/escrow-contract-abi.json";
import marketPlaceContractAbi from "../../../ABIs/marketplace-contract-abi.json";

// hooks
import { useModals, useAppData } from "../../../hooks";
import { ethers } from "ethers";

// helpers
import { formatNumberToFixed } from "../../../helpers";

// constants
const usdtContractAddress = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";
const marketplaceContractAddress = "0xf9BCb7225e8fc27231B495eB35c79F50cF15f4F0";

const BuyNftDialog = () => {
  // state
  const [listingId, setListingId] = useState(null);
  const [salePrice, setSalePrice] = useState(0);
  const [allowance, setAllowance] = useState(null);
  const [claimBalance, setClaimBalance] = useState(null);
  const [showMetadta, setShowMetadata] = useState(false);
  const [escrowContractAddress, setEscrowContractAddress] = useState(null);

  // hooks
  const {
    data: { buyNftModalOpen },
    setBuyNftModalStatus,
  } = useModals();
  const {
    data: { selectedNft },
  } = useAppData();
  const { address, chainId } = useAccount();

  const navigate = useNavigate(); // Hook to programmatically navigate

  // contract hooks
  const { data } = useReadContracts({
    contracts: [
      {
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "getListingIdByTokenId",
        args: [selectedNft.token_address, selectedNft.token_id],
      },
      {
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "finalSalePrice",
        args: [listingId ?? 0],
      },
      {
        abi: usdtAbi,
        chainId: chainId,
        address: usdtContractAddress,
        functionName: "allowance",
        args: [address, marketplaceContractAddress],
      },
      {
        abi: nftContractAbi,
        chainId: chainId,
        address: selectedNft?.token_address ?? "",
        functionName: "escrowAccount",
        args: [selectedNft?.token_id ?? 0],
      },
      ...(escrowContractAddress
        ? [
            {
              abi: escrowContractAbi,
              chainId: chainId,
              address: escrowContractAddress,
              functionName: "getReflectionsBalance",
            },
          ]
        : []),
    ],
  });
  const { writeContractAsync } = useWriteContract();

  // derived data
  const attributes = selectedNft?.metadata
    ? JSON.parse(selectedNft.metadata).attributes ?? []
    : selectedNft.normalized_metadata.attributes;

  // handlers
  const closeModal = () => {
    setBuyNftModalStatus(false);
  };

  const toggleShowMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  const handleBuyNft = async (event) => {
    event.preventDefault();

  // Check if the wallet address is not present, indicating no wallet connection
    if (!address) {
    closeModal();
    navigate('/'); // Redirect to the homepage
    return; // Stop executing the rest of the function
    }


    if (!listingId || !salePrice || !allowance) return;

    try {
      // check the allowance
      const tokenDecimals = 18;
      const parsedAllowance = ethers.parseEther(allowance, tokenDecimals);
      const parsedSalePrice = ethers.parseEther(salePrice, tokenDecimals);
      if (parsedAllowance < parsedSalePrice) {
        const approvalTx = await writeContractAsync({
          abi: usdtAbi,
          chainId: chainId,
          address: usdtContractAddress,
          functionName: "approve",
          args: [marketplaceContractAddress, parsedSalePrice.toString()],
        });
      }

      await new Promise((resolve) => setTimeout(resolve, 10000));

      const txhash = await writeContractAsync({
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "buy",
        args: [listingId],
        value: 0,
      });

      closeModal();

      setTimeout(() => window.location.reload(), 10000);
      

    } catch (error) {
      console.error("handleBuyNft", error);
      //toast.error(`Failed to buy NFT: ${error.message}`);//
    }
  };

  // effects
  useEffect(() => {
    if (data) {
      const [
        listingIdData,
        priceData,
        allowanceData,
        escrowAccountData,
        claimBalanceData,
      ] = data;

      if (listingIdData) {
        setListingId(listingIdData.result);
      }

          // Ensure priceData.result is not null or undefined before formatting
      if (priceData && priceData.result !== null && priceData.result !== undefined) {
      setSalePrice(ethers.formatEther(priceData.result));
      } else {
      console.log('priceData.result is null or undefined');
      }

    // Ensure allowanceData.result is not null or undefined before formatting
      if (allowanceData && allowanceData.result !== null && allowanceData.result !== undefined) {
      setAllowance(ethers.formatEther(allowanceData.result));
      } else {
      console.log('allowanceData.result is null or undefined');
      }

      if (escrowAccountData) {
        setEscrowContractAddress(escrowAccountData.result);
      }

      if (claimBalanceData) {
        setClaimBalance(ethers.formatEther(claimBalanceData.result));
      }
    }
  }, [data]);

  if (!buyNftModalOpen || !selectedNft) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: buyNftModalOpen ? 1 : 0,
        backdropFilter: buyNftModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={buyNftModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="w-full max-w-4xl transform overflow-hidden text-left align-middle"
          >
            <div className="w-full flex flex-col md:flex-row items-center">
              <div className="relative w-full md:w-2/5">
                <img
                  src={selectedNft.normalized_metadata.image}
                  alt={`${selectedNft.name} ${selectedNft.normalized_metadata.name}`}
                  width={512}
                  className="w-full h-auto"
                />
                {showMetadta && (
                  <div className="absolute top-0 w-full h-full bg-black/70 pt-11 pb-6 px-4">
                    <div className="w-full flex flex-col gap-y-3 text-white">
                      {attributes.map((attribute, index) => {
                        const key = Object.keys(attribute)[0];
                        return (
                          <div key={index} className="w-full flex flex-col">
                            <span className="opacity-50">{key}</span>
                            <div className="w-full flex flex-row items-center justify-between">
                              <span>{attribute[key]}</span>
                              <span>{attribute.value}%</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <button
                  onClick={toggleShowMetadata}
                  className="absolute top-2 right-2"
                >
                  <img src="/videos/nfts/info.png" alt="info" />
                </button>
              </div>
              <div className="w-full md:w-3/5 px-1 md:p-6 md:pl-0 text-white">
                <div className="nft-details-bg w-full py-5 px-8">
                  <div className="flex flex-col gap-5">
                    {/* content */}
                    <div className="flex flex-col gap-3">
                      <Dialog.Title as="h3" className="text-base md:text-xl">
                        {" "}
                        {selectedNft.normalized_metadata.name}
                      </Dialog.Title>
                      <div className="pt-3.5 pb-3 px-6 flex flex-col gap-y-1.5 price-bg">
                        <span className="opacity-60 text-sm md:text-base">Price</span>
                        <h4 className="text-base md:text-lg">
                          {salePrice
                            ? `${formatNumberToFixed(salePrice)} USDT`
                            : "Loading..."}
                        </h4>
                      </div>
                      {/*<div className="pt-3.5 pb-3 px-6 flex flex-col gap-y-1.5 price-bg">
                        <span className="opacity-60 text-base">
                      <div className="pt-3.5 pb-3 px-6 flex flex-col gap-y-1.5 price-bg">
                        <span className="opacity-60 text-sm md:text-base">
                          Claim Balance
                        </span>
                        <h4 className="text-base md:text-lg">
                          {claimBalance
                            ? `${formatNumberToFixed(claimBalance)} BYAS`
                            : "Loading..."}
                        </h4>
                          </div>*/}
                    </div>
                    {/* button */}
                    <div>
                      <button
                        onClick={handleBuyNft}
                        className="bg-[#448327] w-full md:w-fit min-h-12 pt-0.5 px-6 flex items-center justify-center gap-2.5"
                      >
                        <span className="text-sm md:text-base leading-5">BUY</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default BuyNftDialog;
