// react
import { createContext } from "react";

// context
const ModalsContext = createContext({
  data: {
    // wallet modals
    burnTokenModalOpen: false,
    sendTokenModalOpen: false,
    receiveTokenModalOpen: false,
    // nft modals
    buyNftModalOpen: false,
    listNftModalOpen: false,
    editNftModalOpen: false,
    claimNftModalOpen: false,
    // Function to update balances, initially set to a no-op function
    updateBalances: () => {},
  },
  setData: (value) => {},
});

// provider
const ModalsProvider = ModalsContext.Provider;
// consumer
const ModalsConsumer = ModalsContext.Consumer;

export { ModalsProvider, ModalsConsumer };
export default ModalsContext;