// abis
import dxoAbi from "../ABIs/dxoABI.json";
import byasAbi from "../ABIs/tokenABI.json";
import hundredAbi from "../ABIs/hundredABI.json";

export const erc20Tokens = [
  {
    name: "BYAS",
    symbol: "BYAS",
    address: "0xfb7ac1Bcf58e1B8bB182e0CcF67b301dd4E0E565", // 0x8bcf39CDBAe5d1723e14bE09fF084CD0EdA36c76
    abi: byasAbi,
    burnable: false,
  },
  {
    name: "Dxo",
    symbol: "DXO",
    address: "0xe4fE0D764A73a649Be379D3f294d094D6792a7fC",
    abi: dxoAbi,
    burnable: false,
  },
  {
    name: "Hundred",
    symbol: "HUNDRED",
    address: "0xb349169ffBC9FCE0e3C14fFab856BEfc8AdCeC36",
    abi: hundredAbi,
    burnable: false,
  },
  {
    name: "Btc",
    symbol: "BTC",
    address: "0xb6e068Cf0506Ef1A14351296AFc5a843867319C6",
    abi: hundredAbi,
    burnable: true,
  },
];

export const burnableTokens = erc20Tokens.filter((token) => token.burnable);
