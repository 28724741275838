// imports
import { useAccount } from "wagmi";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// styles
import "./GalleryPage.css";

// components
import { NftCard } from "../shared";

// hooks
import { useGetNftsByWallet } from "../../hooks";

const GalleryPage = () => {
  const { address } = useAccount();
  const { data, isLoading, error } = useGetNftsByWallet({
    limit: 50,
    userAddress: address,
  });

  // Loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Error state
  if (error) {
    return <div className="relative top-80">Error loading the gallery: {error.message}</div>;
  }

  // Data loaded successfully
  return (
    <div className="market">
      {/* tabs sections */}
      <section className="pt-[146px]">
        <div className="max-w-[1500px] mx-auto px-6 md:px-[75px] flex flex-col items-start justify-start">
          <div className="flex flex-row gap-x-3 text-white leading-[42px]">
            <Link to="/gallery">
              <h2 className="text-base md:text-2xl">Gallery</h2>
            </Link>
            <h2 className="text-base md:text-2xl">/</h2>
            <Link to="/nft">
              <h2 className="opacity-50 text-base md:text-2xl">Marketplace</h2>
            </Link>
          </div>
        </div>
      </section>
      {/* NFTs list */}
      <section className="py-8 px-4">
        <div className="max-w-[1640px] mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {/* nft cards */}
            {data && data.length > 0 ? (
              data.map((nft) => {
                // Attempt to safely parse the metadata and check for attributes
                let metadata = {};
                try {
                  metadata = JSON.parse(nft.metadata || '{}');
                } catch (error) {
                  console.error("Error parsing metadata for NFT:", nft.token_id, error);
                  // Skip rendering this NFT if metadata parsing fails
                  return null;
                }

                // Check if attributes exist within the metadata before rendering the NftCard
                if (metadata.attributes) {
                  return <NftCard key={nft.token_hash} page="gallery" nft={nft} />;
                } else {
                  // Optionally, handle NFTs without attributes differently
                  return null;
                }
              })
            ) : null 
            }
            
          </div>
        </div>
      </section>
    </div>
  );
};


export default GalleryPage;
