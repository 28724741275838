// imports
import { Link, Outlet, useLocation } from "react-router-dom";

// styles
import "./MarketPage.css";

// helpers
import { classnames } from "../../helpers";

const MarketPage = () => {
  // router hooks
  const location = useLocation();

  // determine if the current page is the buy or list page
  const currentPath = location.pathname;
  const isBuyPage = currentPath === "/marketplace";
  const isListPage = currentPath === "/marketplace/list";

  return (
    <div className="market">
      {/* tabs sections */}
      <section className="pt-[146px]">
        <div className="max-w-[1500px] mx-auto px-6 md:px-[75px] flex flex-col items-start justify-start">
          <div className="flex flex-row gap-x-3 text-white text-[28px] leading-[42px]">
            <Link to="/nft">
              <h2 className="text-base md:text-2xl">Marketplace</h2>
            </Link>
            <h2 className="text-base md:text-2xl">/</h2>
            <Link to="/gallery">
              <h2 className="opacity-50 text-base md:text-2xl">Gallery</h2>
            </Link>
          </div>
        </div>
      </section>
      {/* nfts list */}
      <section className="py-8 px-4">
        <div className="max-w-[1640px] mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {/* nft cards */}
            <Outlet />
          </div>
        </div>
      </section>
    </div>
  );
};

export default MarketPage;
