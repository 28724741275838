// react
import React, { useEffect } from "react";
// imports
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react"; // Import useWeb3Modal

// styles
import "./ConnectButton.css";
import { useWindowWidth } from "@react-hook/window-size";
import { useLocation, useNavigate } from "react-router-dom";

const ConnectButton = ({
  imageSrc: { connectedImage, disconnectedImage },
  customClass,
  style,
  className,
}) => {
  // useWeb3Modal hooks
  const { open } = useWeb3Modal();
  const { disconnect, reset } = useDisconnect();
  const location = useLocation();
  const navigate = useNavigate();
  // const onlyWidth = useWindowWidth()

  // useEffect(() => {
  //   console.log({onlyWidth});
  // }, [onlyWidth])

  // useAccount hook
  const { isConnected, isConnecting } = useAccount();

  const handleClick = async () => {
    if (isConnected) {
      // If the wallet is connected
      reset();
      disconnect(); // Disconnect the wallet
    } else {
      open(); // Open the modal
    }
  }; //the modal itself has connect and disconnect options so its a matter of timing the image changes

  useEffect(() => {
    if (isConnected) {
      if (location.pathname === "/") {
        setTimeout(() => {
          navigate("/about");
        }, 1000);
      }
    }
  }, [isConnected]);

  return (
    <div className={className}>
      <img
        src={isConnected ? connectedImage : disconnectedImage}
        alt={
          isConnecting ? "Connecting" : isConnected ? "Connect" : "Disconnect"
        }
        onClick={handleClick}
        className={customClass}
        style={{ cursor: "pointer", ...style }}
      />
    </div>
  );
};

export default ConnectButton;
