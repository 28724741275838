// react
import { useEffect, useState } from "react";
// imports
import { useAccount } from "wagmi";
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";

// imports
import "./ReceiveTokensDialog.css";

// hooks
import { useModals } from "../../../hooks";

// helpers
import { addressToQRCode, copyToClipboard } from "../../../helpers";

const ReceiveTokensDialog = () => {
  // state
  const [copied, setCopied] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);

  // hooks
  const {
    data: { receiveTokenModalOpen },
    setReceiveTokensModalStatus,
  } = useModals();
  const { address } = useAccount();

  // handlers
  const closeModal = () => {
    setReceiveTokensModalStatus(false);
    setCopied(false);
    setQrCodeUrl(null);
  };

  const handleCopyAddress = () => {
    const result = copyToClipboard(address);
    setCopied(result);
  };

  // effects
  useEffect(() => {
    if (receiveTokenModalOpen && address) {
      const url = addressToQRCode(address, { width: 200, height: 200 });
      setQrCodeUrl(url);
    }
  }, [address, receiveTokenModalOpen]);

  if (!receiveTokenModalOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: receiveTokenModalOpen ? 1 : 0,
        backdropFilter: receiveTokenModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={receiveTokenModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="dialog-bg w-full max-w-md transform overflow-hidden rounded-2xl text-white p-6 text-left align-middle flex flex-col items-center justify-center gap-5"
          >
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6"
            >
              RECEIVE TOKENS
            </Dialog.Title>

            <div className="mt-4 flex flex-col gap-y-5">
              {/* image */}
              <img
                src={qrCodeUrl}
                alt="QR Code"
                className="w-40 h-40 mx-auto"
              />

              {/* address */}
              <div className="flex items-center justify-center gap-x-2 flex-wrap">
                <p className="text-[8px] md:text-xs text-white/60">{address}</p>
                <button
                  type="button"
                  className="text-xs text-yellow-400 hover:underline"
                  onClick={handleCopyAddress}
                >
                  {copied ? "Copied!" : "Copy"}
                </button>
              </div>
            </div>

            <div className="mt-4">
              <button
                type="button"
                className="flex items-center justify-center bg-white min-w-64 h-12 px-6 py-2 text-sm font-medium text-[#9B8D47] focus:outline-none"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default ReceiveTokensDialog;
