// react
import { useEffect, useState, useRef } from "react";
// imports
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import {
  useAccount,
  useBalance,
  useReadContracts,
  useSendTransaction,
  useWriteContract,
} from "wagmi";

import { toast } from "react-toastify";

// styles
import "./SendTokensDialog.css";

// components
import TokensList from "./TokensList";
import SendTokenForm from "./SendTokenForm";


// hooks
import { useModals } from "../../../hooks";

// data
import { erc20Tokens } from "../../../data";

const SendTokensDialog = () => {
  // refs
  const formRef = useRef(null);

  // state
  const [selectedToken, setSelectedToken] = useState(null);

  // hooks
  const {
    data: { sendTokenModalOpen },
    setSendTokensModalStatus,
  } = useModals();
  const { address, chainId } = useAccount();


  // contract hooks
  const { data: balance } = useBalance({
    address: address,
  });
  const { data: balances } = useReadContracts({
    contracts: erc20Tokens.map((token) => ({
      abi: token.abi,
      chainId: chainId,
      address: token.address,
      functionName: "balanceOf",
      args: [address],
    })),
  });
  const { sendTransactionAsync } = useSendTransaction();
  const { writeContractAsync } = useWriteContract();

  // handlers
  const closeModal = () => {
    setSendTokensModalStatus(false);
    setSelectedToken(null);
  };

  const handleSelectToken = (token) => {
    setSelectedToken(token);
  };

  const handleFormSubmit = () => {
    formRef?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
    
  const handleSendTokens = async ({ recipient, amount }) => {
    
      if (selectedToken.isNative) {
        // Send native token
      try{
        const txHash = await sendTransactionAsync({
          to: recipient,
          value: amount,
          chainId: chainId,
        });
        console.log(`Transaction sent with hash:", ${txHash}`);

        closeModal();

      } catch (error) {
        console.error(error);
        toast.error(
          `An error occurred while sending the tokens: ${error.message}`
        );
       }

      } else {
        // Send ERC20 token
        const token = erc20Tokens.find(t => t.address === selectedToken.address);
       try{
        const txHash = await writeContractAsync({
          abi: token.abi,
          chainId: chainId,
          address: token.address,
          functionName: "transfer",
          args: [recipient, amount],
        });
        console.log(`Transaction sent with hash:", ${txHash}`);
      
        closeModal();

    } catch (error) {
      console.error("Error sending tokens:", error);
      toast.error(`Error sending tokens: ${error.message}`);
    }
  }
  };
  

  // effects
  useEffect(() => {
    // shutdown cleanup
    return () => {
      setSelectedToken(null);
    };
  }, []);

  if (!sendTokenModalOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: sendTokenModalOpen ? 1 : 0,
        backdropFilter: sendTokenModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={sendTokenModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="dialog-bg w-full transform overflow-hidden rounded-2xl text-white p-6 text-left align-middle flex flex-col items-center justify-center gap-5"
          >
            <div className="text-center flex flex-col gap-2.5">
              <Dialog.Title as="h3" className="text-2xl font-medium leading-6">
                SEND TOKENS
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-white/60">
                  {!Boolean(selectedToken)
                    ? "Select the token you would like to send."
                    : `Enter the recipient's address and the ${selectedToken.symbol} amount
                    you would like send`}
                </p>
              </div>
            </div>

            {!Boolean(selectedToken) ? (
              <TokensList
                balance={balance}
                balances={balances}
                erc20Tokens={erc20Tokens}
                onSelectToken={handleSelectToken}
              />
            ) : (
              <SendTokenForm
                formRef={formRef}
                address={address}
                selectedToken={selectedToken}
                onSendTokens={handleSendTokens}
              />
            )}

            <div className="mt-4 flex flex-col gap-y-2">
              {Boolean(selectedToken) && (
                <button
                  type="button"
                  className="flex items-center justify-center bg-white min-w-64 h-12 px-6 py-2 text-sm font-medium text-[#9B8D47] focus:outline-none"
                  onClick={handleFormSubmit}
                >
                  Send
                </button>
              )}
              <button
                type="button"
                className="flex items-center justify-center bg-white min-w-64 h-12 px-6 py-2 text-sm font-medium text-[#9B8D47] focus:outline-none"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default SendTokensDialog;
