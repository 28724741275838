// imports
import { useAccount } from "wagmi";

// components
import { NftCard } from "../shared";

// hooks
import { useGetNftsByWallet } from "../../hooks";

const BuyNfts = () => {
  // hooks
  const { address } = useAccount();
  // data hooks
  const { data, loading, error } = useGetNftsByWallet({
    limit: 50,
    userAddress: address,
  });

  return (
    <>
      {data?.map((nft) => (
        <NftCard key={nft.token_hash} page="marketplace" nft={nft}/>
      ))}
    </>
  );
};

export default BuyNfts;
