// react
import { useContext } from "react";

// context
import { AppContext } from "../contexts";

const useAppData = () => {
  const { data, setData } = useContext(AppContext);

  return {
    data,
    setAppData: setData,
  };
};

export default useAppData;