// react
import React, { useContext } from "react";
import { useState, useEffect, useCallback } from "react";
// imports
import axios from "axios";
import { ethers } from "ethers";
import { useMediaQuery } from "react-responsive";
import {
  useAccount,
  useDisconnect,
  useSignMessage,
  useReadContract,
  useWriteContract,
  useWatchBlockNumber,
} from "wagmi";

import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { ModalsContext } from "../../contexts";

// styles
import "./MiningPage.css";

// assets
import SmallCoin from "../../assets/images/themes/gold/buttons/SmallCoin.png";
import CryptoBoard from "../../assets/images/themes/gold/buttons/Background.png";
import GamingInterface from "../../assets/images/themes/gold/buttons/GoldConsole2.png";
import defaultUserProfileAvatar from "../../assets/images/Avatar.png";

// abis
import tokenContractABI from "../../ABIs/tokenABI.json";
import nftContractABI from "../../ABIs/nftABI.json";
import { useModals } from "../../hooks";

// constants
const tokenContractAddress = "0xfb7ac1Bcf58e1B8bB182e0CcF67b301dd4E0E565"; // Replace with your byas contract address
const nftContractAddress = "0x7bE8bA2250d9Ee765B5C23F546AA8Ef1b93B58d7"; // Replace with your NFT contract address
const imageReducingFactor = 1.2; // Adjust this as needed
const { normalizeAddress, backendUrl } = require("../utils");

const defaultTokens = [
  { name: "BYAS", balance: "Loading..." },
  { name: "DXO", balance: "Loading..." },
  { name: "HUNDRED", balance: "Loading..." },
  { name: "BNB", balance: "Loading...", isErc20: false },
  { name: "BTC", balance: "Loading..." },
  // Add other tokens as needed
];

// components
const Popup = ({ message, onClose }) => {
  return (
    <div className="popup-container" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

const MiningPage2 = () => {
  // state
  const [userAddress, setUserAddress] = useState(null);
  const [balances, setBalances] = useState({});
  const [userPercentage, setUserPercentage] = useState(0);
  const [dinoStages, setDinoStages] = useState(null);
  const [tokenContracts, setTokenContracts] = useState([]);
  const [currentImageSrc, setImageSrc] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [displayedTokens, setDisplayedTokens] = useState([]);
  const [profilepicUrl, setProfilePicUrl] = useState(null);

  // hooks
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { open } = useWeb3Modal();
  const { isConnected, chainId, address } = useAccount();
  const { disconnect } = useDisconnect();
  useWatchBlockNumber({
    onBlockNumber: (blockNumber) => {
      userAddress && fetchAllBalances(userAddress);
    },
  });
  // modals hooks
  const { setSendTokensModalStatus, setReceiveTokensModalStatus } = useModals();

  // contract hooks
  const { signMessage } = useSignMessage();
  const { data: allowance } = useReadContract({
    abi: tokenContractABI,
    chainId: chainId,
    address: tokenContractAddress,
    functionName: "allowance",
    args: [userAddress, nftContractAddress],
  });
  const { writeContractAsync: approveTx } = useWriteContract({
    abi: tokenContractABI,
    chainId: chainId,
    address: tokenContractAddress,
    functionName: "approve",
  });
  const { writeContractAsync: mintNftTx } = useWriteContract({
    abi: nftContractABI,
    chainId: chainId,
    address: nftContractAddress,
    functionName: "mintNFT",
  });

  // handlers
  const handleClick = async () => {
    if (isConnected) {
      // If the wallet is connected
      disconnect(); // Disconnect the wallet
    } else {
      open(); // Open the modal
    }
  };

  const getUserData = useCallback(async (address) => {
    try {
      console.log("Getting user data for address:", address);
      const normalizedAddress = normalizeAddress(address);
      const response = await axios.get(
        `${backendUrl}/api/user/${normalizedAddress}`
      );

      console.log("Response for user data:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error getting user data:", error);
      return null;
    }
  }, []);

  // Fetch all token balances from the backend
  const fetchAllBalances = useCallback(
    async (address) => {
      const normalizedAddress = normalizeAddress(address);
      console.log("Fetching balances for address:", normalizedAddress);

      for (const token of tokenContracts) {
        try {
          // before was token.isErc20
          let url = token.isErc20
            ? `${backendUrl}/api/token/erc20/${normalizedAddress}/${token.contractAddress}`
            : `${backendUrl}/api/token/bnb/${normalizedAddress}`;

          const response = await axios.get(url);
          console.log(`Response for ${token.name}:`, response.data);

          if (response && response.data) {
            let balance = response.data.balance;
            if (typeof balance === "string" && !isNaN(balance)) {
              setBalances((prevBalances) => ({
                ...prevBalances,
                [token.name]: parseFloat(balance).toFixed(2),
              }));

              if (token.name === "BYAS" && response.data.userPercentage) {
                const percentage = parseFloat(response.data.userPercentage);
                setUserPercentage(percentage);
              }
            } else {
              console.error(
                `Unexpected response structure for ${token.name}:`,
                response
              );
            }
          } else {
            console.error(
              `Unexpected response structure for ${token.name}:`,
              response
            );
          }
        } catch (error) {
          console.error(`Error fetching balance for ${token.name}:`, error);
        }
      }
    },
    [tokenContracts]
  );

  const { data: modalsData, setData: setModalsData } =
    useContext(ModalsContext);

  useEffect(() => {
    setModalsData((prevData) => ({
      ...prevData,
      fetchAllBalances, // Provide the actual function to update balances
    }));
  }, [fetchAllBalances, setModalsData]);

  const fetchTokenContracts = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/token-info`);
      let data = response.data;

      if (typeof data === "string") {
        data = JSON.parse(data);
      }

      // Check if BNB is already in the list and add it if it's not
      const bnbExists = data.some((contract) => contract.name === "BNB");
      if (!bnbExists) {
        data.push({ name: "BNB", isErc20: false }); // Add BNB
      }

      setTokenContracts(data); // Update state with the complete list, including BNB
    } catch (error) {
      console.error("Error fetching token contracts:", error);
    }
  }, []);

  // Update user data on the backend
  const putUserData = async (address, data) => {
    try {
      const normalizedAddress = normalizeAddress(address);
      await axios.put(`${backendUrl}/api/user/${normalizedAddress}`, data);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  // Get the current image source based on user's balance
  const getCurrentImageSrc = useCallback(() => {
    console.log("Getting current image source", balances["BYAS"]);
    if (!balances["BYAS"] || !dinoStages) return null;

    const balanceNum = parseFloat(balances["BYAS"]);
    let currentStageIndex = 0; // Initialize with the first stage index.

    dinoStages.forEach((stage, index) => {
      const thresholdNum = parseFloat(stage.threshold);
      if (balanceNum >= thresholdNum) {
        currentStageIndex = index + 1; // +1 for 1-based indexing
      }
    });

    const stage = dinoStages[currentStageIndex - 1]; // -1 to convert back to 0-based for accessing the array
    if (stage) {
      putUserData(userAddress, { dinoStages, currentStage: currentStageIndex });
      return { mainSrc: stage.src };
    } else {
      return null;
    }
  }, [balances, dinoStages, userAddress]);

  // Disconnect the wallet
  const onWalletDisconnected = useCallback(() => {
    setUserAddress(null);
    setBalances(
      defaultTokens.reduce(
        (acc, token) => ({
          ...acc,
          [token.name]: "Loading...",
        }),
        {}
      )
    );
    setDisplayedTokens(defaultTokens);
    setDinoStages(null);
    setUserPercentage(0);
    setImageSrc(null);
    setProfilePicUrl(null);
  }, []);

  // Fetch balances whenever the userAddress or tokenContracts change
  // Set up the accountsChanged event listener
  const accountsChanged = useCallback(
    async (accounts) => {
      if (accounts.length > 0) {
        console.log("Accounts Changed:", accounts);
        const normalizedAddress = normalizeAddress(accounts[0]);
        setUserAddress(normalizedAddress);

        // Fetch user data for the new account
        const userData = await getUserData(normalizedAddress);
        if (userData) {
          // Set the animal stages from user data
          setDinoStages(userData.dinoStages);
          const currentStage = userData.dinoStages[userData.currentStage - 1];
          if (currentStage) {
            setImageSrc(currentStage.src); // Set the image for the current stage
          }

          setUserPercentage(userData.userPercentage || 0); // Set user percentage if available
        } else {
          // No user data means this is likely a new user
          console.log("New user detected, awaiting random animal assignment.");
        }

        // Introduce a slight delay before fetching balances for the new account
        setTimeout(() => {
          if (userAddress) {
            fetchAllBalances(normalizedAddress);
          }
        }, 1000); // Adjust delay as needed
      } else {
        // User has disconnected their wallet
        onWalletDisconnected();
      }
    },
    [userAddress, onWalletDisconnected, fetchAllBalances, getUserData]
  ); // Dependencies for useCallback

  const onWalletConnected = useCallback(() => {
    console.log("Wallet connected");
    // Fetch the current account and apply the account change logic
    if (window?.ethereum) {
      window?.ethereum
        ?.request({ method: "eth_accounts" })
        ?.then((accounts) => {
          if (accounts.length > 0) {
            accountsChanged(accounts);
          }
        })
        ?.catch((error) => {
          console.error("Error fetching accounts on wallet connect:", error);
        });
    } else {
      if (address) {
        accountsChanged([address]);
      }
    }
  }, [accountsChanged]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (!userAddress) return; // Exit if userAddress is not set

        const normalizedAddress = normalizeAddress(userAddress);
        const response = await axios.get(
          `${backendUrl}/api/user/${normalizedAddress}`
        );

        if (response.data && response.data.profilePic) {
          setProfilePicUrl(response.data.profilePic); // Directly use the URL from the response
        } else {
          setProfilePicUrl(defaultUserProfileAvatar); // Use default avatar if no profile pic
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, [userAddress]);

  const handleMintClick = async () => {
    try {
      // Check and initiate approval if necessary
      console.log("Current allowance:", ethers.formatUnits(allowance, 18));

      if (allowance < ethers.parseUnits("324646541646546846844764168456468464684164646", 18)) {
        console.log("Need to approve tokens");
        const amount = ethers.parseEther("324646541646546846844764168456468464684164646");
        console.log("Amount to approve:", amount);
        console.log("nftContractAddress:", nftContractAddress);
        await approveTx({
          address: tokenContractAddress,
          abi: tokenContractABI,
          functionName: "approve",
          args: [normalizeAddress(nftContractAddress), amount], // ethers.MaxUint256
        });
        console.log("Tokens approved successfully");
      } else {
        console.log("Tokens already approved");
      }

      // Generate a signature for minting (as a form of user agreement)
      const message = `I, ${userAddress}, agree to mint an NFT at ${new Date().toISOString()}. I understand this will incur a transaction fee and the transfer of my BYAS tokens to a unique escrow account that I control.`;
      signMessage(
        { message },
        {
          onSuccess: async (signature) => {
            try {
              console.log({ signature, message });
              const verificationResponse = await axios.post(
                `${backendUrl}/api/verifySignature`,
                {
                  userAddress,
                  message,
                  signature,
                }
              );

              let mintTxHash;
              
              const overrides = {
                gasLimit: ethers.parseUnits("2000000000000000000000000", "wei"), // Adjust the gas limit based on your contract's needs
              };

              // Check if the backend verified the signature
              if (verificationResponse.data.verified) {
                console.log("Signature verified!");
                // Now that we're sure the user has approved and the signature is verified, proceed to mint
                mintTxHash = await mintNftTx({
                  address: nftContractAddress,
                  abi: nftContractABI,
                  functionName: "mintNFT",
                  args: [userAddress, 0],
                  overrides,
                });

                // If minting was successful, update the UI and balances
                
              }

              await fetchAllBalances(userAddress); // Fetch updated balances
            } catch (error) {
              if (error.code === 4001) {
                // User rejected the transaction
                console.log("User rejected the transaction.");
                toast.info("Minting cancelled by the user.");
              } else {
                // Other errors
                console.error("Minting error:", error);
                toast.error(`Error during the minting process: ${error.message}`);
              }
            }
          },

          onError: (error) => {
            console.error("Error signing message:", error);
            toast.error(`Error signing message: ${error.message}`);
          },
        }
      );
    } catch (error) {
      console.error("Error during the minting process:", error);
      toast.error(`Error during the minting process: ${error.message}`);
    }
  };

  // effects
  useEffect(() => {
    if (isConnected) {
      console.log("Wallet connected");
      onWalletConnected();
    } else {
      console.log("Wallet disconnected");
      onWalletDisconnected();
    }
  }, [isConnected, onWalletConnected, onWalletDisconnected]);

  useEffect(() => {
    if (userAddress) {
      // Logic to update tokens' balances
      const updatedTokens = tokenContracts.map((token) => ({
        ...token,
        balance: balances[token.name]
          ? Number(balances[token.name]).toFixed(2)
          : "Loading...",
      }));
      setDisplayedTokens(updatedTokens);
    } else {
      // Reset to default tokens when user disconnects
      setDisplayedTokens(defaultTokens);
    }
  }, [userAddress, tokenContracts, balances]);

  // Fetch token contracts on mount
  useEffect(() => {
    const fetchData = async () => {
      await fetchTokenContracts();
    };

    fetchData();
  }, [fetchTokenContracts]);

  // Update currentImageSrc whenever balances or dinoStages change
  useEffect(() => {
    const newImageSrc = getCurrentImageSrc();
    setImageSrc(newImageSrc); // Update the state with the new image source
    console.log("Updated currentImageSrc:", newImageSrc);
  }, [balances, dinoStages, getCurrentImageSrc]);

  useEffect(() => {
    if (window.ethereum) {
      const handleAccountsChanged = (accounts) => {
        accountsChanged(accounts);
      };

      window.ethereum.on("accountsChanged", handleAccountsChanged);

      // Clean up the event listener when the component unmounts
      return () => {
        if (window.ethereum?.removeListener) {
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
        }
      };
    }
  }, [tokenContracts, accountsChanged, fetchAllBalances]);

  const openBurnTokensModal = (token) => {
    console.log("openBurnTokensModal called with token:", token);
    try {
      // ... Set the modal state to open and include any token data
      setModalsData((prevData) => ({
        ...prevData,
        burnTokenModalOpen: true,
        selectedToken: token, // pass the token data to the modal
      }));
    } catch (error) {
      console.error("Error in openBurnTokensModal:", error);
    }
  };

  const openSendTokensModal = () => {
    setSendTokensModalStatus(true);
  };

  const openReceiveTokensModal = () => {
    setReceiveTokensModalStatus(true);
  };

  return (
    <div className="page-container">
      {/* Conditionally render the Popup at the top level within app-container */}
      {showPopup && (
        <Popup message={popupMessage} onClose={() => setShowPopup(false)} />
      )}
      {isMobile && (
        <div className="mobile-header-mining">
          <div className="mr-5">
            <span className="text-line">Zero Energy Mining</span>
          </div>
          <div>
            <img
              src={isConnected ? "./images/Stop.png" : "./images/Start.png"}
              alt={isConnected ? "Disconnect Wallet" : "Connect Wallet"}
              width={80 / imageReducingFactor}
              height={42 / imageReducingFactor}
              onClick={handleClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      )}
      <div className="mining-page-wrapper">
        <div className="crypto-board">
          <div className="crypto-board-content">
            {/* connect header */}
            {!isMobile && (
              <div className="connect-header">
                <div className="mr-5">
                  <span className="text-line">Zero Energy Mining</span>
                </div>
                <div>
                  <img
                    src={
                      isConnected ? "./images/Stop.png" : "./images/Start.png"
                    }
                    alt={isConnected ? "Disconnect Wallet" : "Connect Wallet"}
                    width={80 / imageReducingFactor}
                    height={42 / imageReducingFactor}
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            )}
            {/* tokens list */}
            {displayedTokens.map((token, index) => (
              <div
                className="token-item"
                key={token.contractAddress || token.name + index}
              >
                <div className="coin-name">{token.name}</div>
                {/* Conditionally render the burn button */}
                {(token.name === "BNB" || token.name === "BTC") && (
                  <div className="token-list-btn.burn-container">
                    <button
                      className="token-list-btn burn"
                      onClick={() => openBurnTokensModal(token)}
                    >
                      <span>BURN</span>
                    </button>
                  </div>
                )}
                <div className="coin-balance">
                  <img
                    src={SmallCoin}
                    alt="small coin icon"
                    width={38 / imageReducingFactor}
                    height={36 / imageReducingFactor}
                  />
                  {token.balance}
                </div>
              </div>
            ))}
            {/*<div className="add-token-btn">
              <div className="btn-text">+ ADD TOKEN</div>
            </div>*/}
          </div>
        </div>
        <div className="gaming-board">
          {/* Mint Button Container */}
          <div className="gaming-board-content mint-btn">
            <button
              onClick={handleMintClick}
              disabled={
                !(balances["BYAS"] && parseFloat(balances["BYAS"]) >= 2100000)
              }
              className={`mint-button ${
                balances["BYAS"] && parseFloat(balances["BYAS"]) >= 2100000
                  ? "enabled"
                  : "disabled"
              }`}
            ></button>
          </div>
          {/* User Percentage */}
          <div className="gaming-board-content user-percentage">
            {userAddress ? (
              <span>{userPercentage.toFixed(4)}%</span>
            ) : (
              <span>0.00%</span>
            )}
          </div>
          {/* Animal Image */}
          {currentImageSrc && (
            <div className="gaming-board-content current-animal-image">
              <img
                src={currentImageSrc.mainSrc}
                alt="Current Animal Stage"
                width={500 / imageReducingFactor}
                height={500 / imageReducingFactor}
                className="current-animal-image max-w-max w-[calc(500px_/_var(--image-reductionFactor))] h-[calc(500px/_var(--image-reductionFactor))]"
              />
            </div>
          )}
          <div className="gaming-board-content mining-animation">
            {isConnected ? (
              <img
                src="/videos/miner3.gif"
                alt="Mining Animation"
                className="max-w-max w-full md:w-[calc(300px_/_var(--image-reductionFactor))] md:h-[calc(300px/_var(--image-reductionFactor))]"
              />
            ) : (
              <img
                src="/images/miner4.png" // Path to your static/inactive image
                alt="Inactive Mining Animation"
                className="max-w-max w-full md:w-[calc(300px_/_var(--image-reductionFactor))] md:h-[calc(300px/_var(--image-reductionFactor))] opacity-50" // Added opacity to signify inactive state
              />
            )}
          </div>
          <img
            src={GamingInterface}
            alt="GamingInterface"
            width={808 / imageReducingFactor}
            height={808 / imageReducingFactor}
            className="max-w-max w-full h-full md:w-[calc(808px_/_var(--image-reductionFactor))] md:h-[calc(808px/_var(--image-reductionFactor))]"
          />
        </div>
      </div>
      {isMobile && (
        <div className="flex flex-row items-center gap-2 pb-5 px-4">
          <div
            className="header-userbar-btn send w-full justify-center"
            onClick={openSendTokensModal}
          >
            <span>SEND</span>
          </div>
          <div
            className="header-userbar-btn receive w-full justify-center"
            onClick={openReceiveTokensModal}
          >
            <span>RECEIVE</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiningPage2;
export const putUserProfilePic = async (userAddress, formData) => {
  try {
    const response = await axios.post(
      `${backendUrl}/api/user/${userAddress}/uploadProfilePic`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Profile picture updated in database:", response.data);
    return response.data; // or handle as needed
  } catch (error) {
    console.error("Error updating user profile picture:", error);
    // handle error as needed
  }
};

export const getUserData = async (address) => {
  try {
    console.log("Getting user data for address:", address);
    const normalizedAddress = normalizeAddress(address);
    const response = await axios.get(`${backendUrl}/api/user/${normalizedAddress}`);

    console.log("Response for user data:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error getting user data:", error);
    return null;
  }
};
