// react
import { useState } from "react";
// imports
import { ethers } from "ethers";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { Dialog } from "@headlessui/react";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

// styles
import "./EditNftDialog.css";

// abis
import marketPlaceContractAbi from "../../../ABIs/marketplace-contract-abi.json";

// hooks
import { useModals, useAppData } from "../../../hooks";

// constants
const marketplaceContractAddress = "0xf9BCb7225e8fc27231B495eB35c79F50cF15f4F0";

const EditNftDialog = () => {
  // state
  const [showMetadta, setShowMetadata] = useState(false);

  // hooks
  const {
    data: { editNftModalOpen },
    setEditNftModalStatus,
  } = useModals();
  const {
    data: { selectedNft },
  } = useAppData();
  const { chainId } = useAccount();

  // contract hooks
  const { data: listingId } = useReadContract({
    abi: marketPlaceContractAbi,
    chainId: chainId,
    address: marketplaceContractAddress,
    functionName: "getListingIdByTokenId",
    args: [selectedNft.token_address, selectedNft.token_id],
  });
  const { writeContractAsync } = useWriteContract();

  // form hooks
  const { register, handleSubmit } = useForm();

  // derived data
  const attributes = selectedNft?.metadata
  ? JSON.parse(selectedNft.metadata).attributes ?? []
  : selectedNft.normalized_metadata.attributes;

  // handlers
  const closeModal = () => {
    setEditNftModalStatus(false);
  };

  const toggleShowMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  const handleUpdateListing = async (data, event) => {
    event?.preventDefault();

    if (!selectedNft || !listingId) return;

    try {
      const parsedPrice = ethers.parseEther(data.price);

      const txhash = await writeContractAsync({
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "updateListingPrice",
        args: [
          listingId,
          parsedPrice,
        ],
      });

      closeModal();

      {/*const { isConfirmed } = await Swal.fire({
        position: "center",
        icon: "success",
        title: `Update successful! Transaction hash: ${txhash}`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "View on BscScan",
        cancelButtonText: "Close",
        customClass: {
          title: "success-popup",
        },
      });

      if (isConfirmed) {
        window.open(`https://testnet.bscscan.com/tx/${txhash}`, "_blank");
      }*/}
    } catch (error) {
      console.error("handleBuyNft", error);
      toast.error(`Failed updating the listing: ${error.message}`);
    }
  };

  const handleRemoveListing = async (event) => {
    event?.preventDefault();

    if (!selectedNft || !listingId) return;

    try {
      const txHash = await writeContractAsync({
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "removeListing",
        args: [listingId],
      });

      if (txHash) {
        console.log("txHash", txHash);
      }

      closeModal();

    } catch (error) {
      console.error("handleBuyNft", error);
      toast.error(`Failed removing the listing: ${error.message}`);
    }
  };

  if (!editNftModalOpen || !selectedNft) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: editNftModalOpen ? 1 : 0,
        backdropFilter: editNftModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={editNftModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="w-full max-w-4xl transform overflow-hidden text-left align-middle"
          >
            <div className="w-full flex flex-col md:flex-row items-center">
              <div className="relative w-full md:w-2/5">
                <img
                  src={selectedNft.normalized_metadata.image}
                  alt={`${selectedNft.name} ${selectedNft.normalized_metadata.name}`}
                  width={512}
                  className="w-full h-auto"
                />
                {showMetadta && (
                  <div className="absolute top-0 w-full h-full bg-black/70 pt-11 pb-6 px-4">
                    <div className="w-full flex flex-col gap-y-3 text-white">
                      {attributes.map((attribute) => {
                        const key = Object.keys(attribute)[0];
                        return (
                          <div className="w-full flex flex-col">
                            <span className="opacity-50">{key}</span>
                            <div className="w-full flex flex-row items-center justify-between">
                              <span>{attribute[key]}</span>
                              <span>{attribute.value}%</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <button
                  onClick={toggleShowMetadata}
                  className="absolute top-2 right-2"
                >
                  <img src="/videos/nfts/info.png" alt="info" />
                </button>
              </div>
              <div className="w-full md:w-3/5 px-1 md:p-6 md:pl-0 text-white">
                <div className="nft-details-bg w-full py-5 px-8">
                  <form
                    onClick={handleSubmit(handleUpdateListing)}
                    className="flex flex-col gap-5"
                  >
                    {/* content */}
                    <div className="flex flex-col gap-3">
                      <Dialog.Title as="h3" className="text-base md:text-xl">
                        {" "}
                        {selectedNft.normalized_metadata.name}
                      </Dialog.Title>
                      <div className="bg-[#A3834E] h-16 px-4 flex items-center justify-between gap-2.5">
                        <input
                          {...register("price", {
                            required: true,
                            validate: (value) => {
                              const parsedValue = Number(value);

                              // check if the amount is a valid number
                              if (isNaN(parsedValue)) {
                                return "Invalid amount";
                              }

                              // check if the amount is greater than 0
                              if (parsedValue <= 0) {
                                return "Amount must be greater than 0";
                              }

                              // return true if the value is valid
                              return true;
                            },
                          })}
                          placeholder="Price"
                          className="bg-transparent py-2 text-white/60 placeholder:text-white/60 text-sm md:text-base focus:outline-none"
                        />
                        <h4 className="text-base md:text-xl">USDT</h4>
                      </div>
                      {/*<div className="bg-[#A3834E] h-16 px-4 flex items-center justify-between gap-2.5">
                        <input
                          {...register("claimBalance", {
                            required: true,
                            validate: (value) => {
                              const parsedValue = Number(value);

                              // check if the amount is a valid number
                              if (isNaN(parsedValue)) {
                                return "Invalid amount";
                              }

                              // check if the amount is greater than 0
                              if (parsedValue <= 0) {
                                return "Amount must be greater than 0";
                              }

                              // return true if the value is valid
                              return true;
                            },
                          })}
                          placeholder="Claim balance"
                          className="bg-transparent py-2 text-white/60 placeholder:text-white/60 text-sm md:text-base focus:outline-none"
                        />
                        <h4 className="text-base md:text-xl">BYAS</h4>
                        </div>*/}
                    </div>
                    {/* button */}
                    <div className="flex items-center gap-3">
                      <button
                        type="submit"
                        className="bg-[#406DB1] min-h-12 pt-0.5 px-6 flex items-center justify-center gap-2.5"
                      >
                        <span className="text-sm md:text-base text-white leading-5">
                          UPDATE
                        </span>
                      </button>
                      <button
                        type="button"
                        onClick={handleRemoveListing}
                        className="bg-[#A82B2B] min-h-12 pt-0.5 px-6 flex items-center justify-center gap-2.5"
                      >
                        <span className="text-sm md:text-base text-white leading-5">
                          REMOVE
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default EditNftDialog;
