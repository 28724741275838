// components
import { NftCard } from "../shared";

// hooks
import { useGetNftsByWallet } from "../../hooks";

const BuyNfts = () => {
  const { data, loading, error } = useGetNftsByWallet({
    limit: 50,
    userAddress: "0xf9BCb7225e8fc27231B495eB35c79F50cF15f4F0",
  });

  return (
    <>
      {data?.map((nft) => (
        <NftCard key={nft.token_hash} page="marketplace" nft={nft} showClaimBalance={false} />
      ))}
    </>
  );
};

export default BuyNfts;
