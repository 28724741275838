// react
import React, { useState } from "react";
// imports
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

// styles
import "react-toastify/dist/ReactToastify.css";
import "./styles/tailwind.css";
import "./App.css";

// components
import Header from "./components/Header/Header";
import ConnectButton from "./components/WalletConnect/ConnectButton";
import { Web3Modal } from "./components/WalletConnect/WalletProvider"; // Import Web3Modal from WalletProvider.js
import MiningPage from "./components/MiningPage";
import BuyNfts from "./components/MarketPage/BuyNfts";
import ListNfts from "./components/MarketPage/ListNfts";
import AboutPage from "./components/AboutPage";
import MarketPage from "./components/MarketPage";
import GalleryPage from "./components/GalleryPage";
// shared
import {
  BuyNftDialog,
  ListNFTDialog,
  EditNftDialog,
  ClaimNftDialog,
  BurnTokensDialog,
  SendTokensDialog,
  ReceiveTokensDialog,
} from "./components/shared";

// context
import { AppProvider } from "./contexts/app-context";
import { ModalsProvider } from "./contexts/modals-context";

// assets
import startPreviewImage from "./assets/images/themes/gold/buttons/StartPreview.png";
import startPreviewMobile from "./assets/images/themes/gold/buttons/StartPreview-mobile.png";
import disconnectImage from "./assets/images/themes/gold/buttons/DisconnectBig.png";
import disconnectMobile from "./assets/images/themes/gold/buttons/DisconnectBig-mobile.png";

function App() {
  const [appData, setAppData] = useState({});
  const [modalsData, setModalsData] = useState({
    // wallet modals
    burnTokenModalOpen: false,
    sendTokenModalOpen: false,
    receiveTokenModalOpen: false,
    // nft modals
    buyNftModalOpen: false,
    listNftModalOpen: false,
    editNftModalOpen: false,
    claimNftModalOpen: false,
  });

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

  // Conditional image sources based on screen size
  const connectedImageSrc = isDesktopOrLaptop ? disconnectImage : disconnectMobile;
  const disconnectedImageSrc = isDesktopOrLaptop ? startPreviewImage : startPreviewMobile;
 
  return (
    <Web3Modal>
      <Router>
        <AppProvider
          value={{
            data: appData,
            setData: setAppData,
          }}
        >
          <ModalsProvider
            value={{
              data: modalsData,
              setData: setModalsData,
            }}
          >
            <div className="App">
              <Header />
              <Routes>
                <Route
                  index
                  element={             
                    <ConnectButton
                      imageSrc={{
                        connectedImage: connectedImageSrc,
                        disconnectedImage: disconnectedImageSrc,
                      }}
                      customClass="connect-wallet-container"
                      style={{ maxWidth: "550px", marginBottom: "20px" }}
                      className="min-h-dvh flex items-center"
                    />
                  
                  }
                />
                <Route path="/mining" element={<MiningPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/nft" element={<MarketPage />}>
                  <Route index element={<BuyNfts />} />
                  <Route path="list" element={<ListNfts />} />
                </Route>
                <Route path="/gallery" element={<GalleryPage />} />
                {/* ... other routes */}
              </Routes>
            </div>
            {/* modals */}
            {modalsData.buyNftModalOpen && <BuyNftDialog />}
            {modalsData.listNftModalOpen && <ListNFTDialog />}
            {modalsData.editNftModalOpen && <EditNftDialog />}
            {modalsData.claimNftModalOpen && <ClaimNftDialog />}
            <BurnTokensDialog />
            <SendTokensDialog />
            <ReceiveTokensDialog />
            {/* toaster */}
            <ToastContainer
              className="react-toaster-container"
              bodyClassName="!font-sans"
            />
          </ModalsProvider>
        </AppProvider>
      </Router>
    </Web3Modal>
  );
}

export default App;
