// react
import { useEffect, useState } from "react";
// imports
import { ethers } from "ethers";
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useAccount, useReadContracts, useWriteContract } from "wagmi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

// styles
import "./ClaimNftDialog.css";

// abis
import nftContractAbi from "../../../ABIs/nftABI.json";
import escrowContractAbi from "../../../ABIs/escrow-contract-abi.json";

// hooks
import { useModals, useAppData } from "../../../hooks";

// helpers
import { formatNumberToFixed } from "../../../helpers";

const ClaimNftDialog = () => {
  // state
  const [claimBalance, setClaimBalance] = useState(null);
  const [showMetadta, setShowMetadata] = useState(false);
  const [escrowContractAddress, setEscrowContractAddress] = useState(null);

  // hooks
  const {
    data: { claimNftModalOpen },
    setClaimNftModalStatus,
  } = useModals();
  const {
    data: { selectedNft },
  } = useAppData();
  const { chainId } = useAccount();

  // contract hooks
  const { data } = useReadContracts({
    contracts: [
      {
        abi: nftContractAbi,
        chainId: chainId,
        address: selectedNft?.token_address ?? "",
        functionName: "escrowAccount",
        args: [selectedNft?.token_id ?? 0],
      },
      ...(escrowContractAddress
        ? [
            {
              abi: escrowContractAbi,
              chainId: chainId,
              address: escrowContractAddress,
              functionName: "getReflectionsBalance",
            },
          ]
        : []),
    ],
  });
  const { writeContractAsync } = useWriteContract();

  // derived data
  const attributes = selectedNft?.metadata
    ? JSON.parse(selectedNft.metadata).attributes ?? []
    : selectedNft.normalized_metadata.attributes;

  // handlers
  const closeModal = () => {
    setClaimNftModalStatus(false);
  };

  const toggleShowMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  const handleClaimNftReflections = async () => {
    if (!escrowContractAddress) return;

    try {
      const txhash = await writeContractAsync({
        abi: escrowContractAbi,
        chainId: chainId,
        address: escrowContractAddress,
        functionName: "claimReflections",
      });
      
      closeModal();

      {/*const { isConfirmed } = await Swal.fire({
        position: "center",
        icon: "success",
        title: `Claim successful! Transaction hash: ${txhash}`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "View on BscScan",
        cancelButtonText: "Close",
        customClass: {
          title: "success-popup",
        },
      });

      if (isConfirmed) {
        window.open(`https://testnet.bscscan.com/tx/${txhash}`, "_blank");
      }*/}

    } catch (error) {
      console.error("handleBuyNft", error);
      toast.error(`Error claiming reflections tokens: ${error.message}`);
    }
  };

  // effects
  useEffect(() => {
    if (data) {
      const [escrowAccountData, claimBalanceData] = data;

      if (escrowAccountData) {
        setEscrowContractAddress(escrowAccountData.result);
      }

      if (claimBalanceData) {
        setClaimBalance(ethers.formatEther(claimBalanceData.result));
      }
    }
  }, [data]);

  if (!claimNftModalOpen || !selectedNft) return null;

  return (
    <motion.div
      initial={{ opacity: 0, backdropFilter: "blur(0px)" }}
      animate={{
        opacity: claimNftModalOpen ? 1 : 0,
        backdropFilter: claimNftModalOpen ? "blur(10px)" : "blur(0px)",
      }}
      transition={{ duration: 0.3 }}
      className="fixed inset-0 bg-black/25 z-20"
    >
      <Dialog
        open={claimNftModalOpen}
        onClose={closeModal}
        as={motion.div}
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="fixed inset-0 overflow-y-auto z-30"
      >
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel
            as={motion.div}
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="w-full max-w-4xl transform overflow-hidden text-left align-middle"
          >
            <div className="w-full flex flex-col md:flex-row items-center">
              <div className="relative w-full md:w-2/5">
                <img
                  src={selectedNft.normalized_metadata.image}
                  alt={`${selectedNft.name} ${selectedNft.normalized_metadata.name}`}
                  width={512}
                  className="w-full h-auto"
                />
                {showMetadta && (
                  <div className="absolute top-0 w-full h-full bg-black/70 pt-11 pb-6 px-4">
                    <div className="w-full flex flex-col gap-y-3 text-white">
                      {attributes.map((attribute, index) => {
                        const key = Object.keys(attribute)[0];
                        return (
                          <div key={index} className="w-full flex flex-col">
                            <span className="opacity-50">{key}</span>
                            <div className="w-full flex flex-row items-center justify-between">
                              <span>{attribute[key]}</span>
                              <span>{attribute.value}%</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <button
                  onClick={toggleShowMetadata}
                  className="absolute top-2 right-2"
                >
                  <img src="/videos/nfts/info.png" alt="info" />
                </button>
              </div>
              <div className="w-full md:w-3/5 px-1 md:p-6 md:pl-0 text-white">
                <div className="nft-details-bg w-full py-5 px-8">
                  <div className="flex flex-col gap-5">
                    {/* content */}
                    <div className="flex flex-col gap-3">
                      <Dialog.Title as="h3" className="text-base md:text-xl">
                        {" "}
                        {selectedNft.normalized_metadata.name}
                      </Dialog.Title>
                      <div className="pt-3.5 pb-3 px-6 flex flex-col gap-y-1.5 price-bg">
                        <span className="opacity-60 text-sm md:text-base">
                          Claim Balance
                        </span>
                        <h4 className="text-base md:text-xl">
                          {claimBalance ? `${formatNumberToFixed(claimBalance)} BYAS` : "Loading..."}
                        </h4>
                      </div>
                    </div>
                    {/* button */}
                    <div>
                      <button
                        onClick={handleClaimNftReflections}
                        className="bg-[#448327] w-full md:w-fit min-h-12 pt-0.5 px-6 flex items-center justify-center gap-2.5"
                      >
                        <span className="text-sm md:text-base leading-5">CLAIM</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default ClaimNftDialog;
