// src/WalletProvider.js
import React from "react";
import { WagmiProvider } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// 0. Setup queryClient
const queryClient = new QueryClient();
const projectId = "dbd362893a60fe3811ae615086d5aa75";

// 2. Create wagmiConfig
const metadata = {
  name: "Dino Biti",
  description: "Dino Biti NFTs",
  url: "https://mining.byas.app/", // origin must match your domain & subdomain
  icons: ["https://byas.app/Images/Logo.png"],
};

const chains = [bsc, bscTestnet];
const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
});

// 3. Create modal
createWeb3Modal({ wagmiConfig: config, projectId, chains });

export function Web3Modal({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
