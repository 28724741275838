// imports
import { ethers } from "ethers";
import { useForm } from "react-hook-form";

// helpers
import { normalizeAddress } from "../../../helpers";

const SendTokenForm = ({ formRef, address, selectedToken, onSendTokens }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, event) => {
    event?.preventDefault();

    onSendTokens({
      recipient: data.recipient,
      amount: ethers.parseUnits(data.amount, 18), // convert the amount to wei
    });
  };

  return (
    <div className="max-w-lg w-full mt-4">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-5">
          {/* recipient address */}
          <div className="flex flex-col gap-y-2">
            <label
              htmlFor="recipient"
              className="block text-sm font-medium text-white text-[13px]"
            >
              Recipient Address
            </label>
            <input
              type="text"
              id="recipient"
              {...register("recipient", {
                required: true,
                validate: (value) => {
                  // check if the recipient address is a valid ethereum address
                  if (!ethers.isAddress(value)) {
                    return "Invalid recipient address";
                  }

                  // check if he recipient address is the same as the sender's address
                  if (normalizeAddress(value) === normalizeAddress(address)) {
                    return "Recipient address cannot be the same as the sender's address";
                  }

                  // return true if the recipient address is valid
                  return true;
                },
              })}
              placeholder="0x..."
              className="block w-full h-12 py-2 px-3 bg-[#A3834E] text-white/60 placeholder:text-white/60 focus:outline-none focus:border-none sm:text-sm"
            />
            {errors.recipient && (
              <p className="text-red-500 text-xs">{errors.recipient.message}</p>
            )}
          </div>

          {/* amount */}
          <div className="flex flex-col gap-y-2">
            <label
              htmlFor="amount"
              className="block text-sm font-medium text-white text-[13px]"
            >
              Amount To Send
            </label>
            <input
              id="amount"
              type="string"
              {...register("amount", {
                required: true,
                validate: (value) => {
                  const parsedValue = Number(value);

                  // check if the amount is a valid number
                  if (isNaN(parsedValue)) {
                    return "Invalid amount";
                  }

                  // check if the amount is greater than 0
                  if (parsedValue <= 0) {
                    return "Amount must be greater than 0";
                  }

                  // check if the amount is less than the sender's balance
                  const parsedValueInWei = ethers.parseUnits(value, 18);
                  if (parsedValueInWei > selectedToken.balance) {
                    return "Insufficient balance";
                  }
                },
              })}
              placeholder="0.1"
              className="block w-full h-12 py-2 px-3 bg-[#A3834E] text-white/60 placeholder:text-white/60 focus:outline-none focus:border-none sm:text-sm"
            />
            {errors.amount && (
              <p className="text-red-500 text-xs">{errors.amount.message}</p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendTokenForm;
