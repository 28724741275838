// react
import { useEffect, useState } from "react";
// imports
import { ethers } from "ethers";

import { useAccount, useReadContracts } from "wagmi";

// abis
import nftContractAbi from "../../ABIs/nftABI.json";
import escrowContractAbi from "../../ABIs/escrow-contract-abi.json";
import marketPlaceContractAbi from "../../ABIs/marketplace-contract-abi.json";

// hooks
import classNames from "../../helpers/classnames";
import { useModals, useAppData } from "../../hooks";

// helpers
import { normalizeAddress, formatNumberToFixed } from "../../helpers";

// constants
const marketplaceContractAddress = "0xf9BCb7225e8fc27231B495eB35c79F50cF15f4F0";
const primaryBtnTitles = {
  buy: { title: "BUY", handler: "handleBuyNft" },
  edit: { title: "EDIT", handler: "handleEditNft" },
  gallery: { title: "CLAIM", handler: "handleClaimNft" },
};

const NftCard = ({
  page,
  nft: {
    token_address,
    token_id,
    owner_of,
    amount,
    token_hash,
    block_number_minted,
    updated_at,
    contract_type,
    name,
    symbol,
    token_uri,
    metadata,
    normalized_metadata,
    last_token_uri_sync,
    last_metadata_sync,
    minter_address,
    possible_spam,
    verified_collection,
  },
    showClaimBalance = true,
}) => {
  // state
  const [listingId, setListingId] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [listingInfo, setListingInfo] = useState(null);
  const [escrowBalance, setEscrowBalance] = useState(null);
  const [claimBalance, setClaimBalance] = useState(null);
  const [showMetadta, setShowMetadata] = useState(false);
  const [escrowContractAddress, setEscrowContractAddress] = useState(null);

  // hooks
  const {
    setBuyNftModalStatus,
    setListNftModalStatus,
    setEditNftModalStatus,
    setClaimNftModalStatus,
    data: modalData
  } = useModals();
  const { setAppData } = useAppData();
  const { address, chainId } = useAccount();

  // derived state
  const isGalleryPage = page === "gallery";
  const isEditCard =
    page === "marketplace" &&
    listingInfo &&
    normalizeAddress(listingInfo.at(0)) === normalizeAddress(address);
  const currentCardStatus = isGalleryPage
    ? "gallery"
    : isEditCard
    ? "edit"
    : "buy";
    
  const metaData = normalized_metadata;
  const attributes = metadata
    ? JSON.parse(metadata).attributes ?? []
    : normalized_metadata.attributes ?? [];

  // contract hooks
  const { data, refetch } = useReadContracts({
    contracts: [
      {
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "getListingIdByTokenId",
        args: [token_address, token_id],
      },
      {
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "finalSalePrice",
        args: [listingId ?? 0],
      },
      {
        abi: nftContractAbi,
        chainId: chainId,
        address: token_address,
        functionName: "escrowAccount",
        args: [token_id],
      },
      {
        abi: marketPlaceContractAbi,
        chainId: chainId,
        address: marketplaceContractAddress,
        functionName: "listings",
        args: [listingId ?? 0],
      },
      ...(escrowContractAddress
        ? [
            {
              abi: escrowContractAbi,
              chainId: chainId,
              address: escrowContractAddress,
              functionName: "getCurrentBalance",
            },
            {
              abi: escrowContractAbi,
              chainId: chainId,
              address: escrowContractAddress,
              functionName: "getReflectionsBalance",
            },
          ]
        : []),
    ],
    query: {
      refetchInterval:5000
    }
  });

  // handlers
  const handlers = {
    handleBuyNft: () => {
      setAppData((prev) => ({
        ...prev,
        selectedNft: {
          name,
          token_id,
          token_address,
          metadata,
          normalized_metadata,
        },
      }));
      setBuyNftModalStatus(true);
    },
    handleListNft: () => {
      setAppData((prev) => ({
        ...prev,
        selectedNft: {
          name,
          token_id,
          token_address,
          metadata,
          normalized_metadata,
        },
      }));
      setListNftModalStatus(true);
    },
    handleEditNft: () => {
      setAppData((prev) => ({
        ...prev,
        selectedNft: {
          name,
          token_id,
          token_address,
          metadata,
          normalized_metadata,
        },
      }));
      setEditNftModalStatus(true);
    },
    handleClaimNft: () => {
      setAppData((prev) => ({
        ...prev,
        selectedNft: {
          name,
          token_id,
          token_address,
          metadata,
          normalized_metadata,
        },
      }));
      setClaimNftModalStatus(true);
    },
  };

  const toggleShowMetadata = () => {
    setShowMetadata((prev) => !prev);
  };

  // effects
  useEffect(() => {
    if (data) {
      const [
        listingIdData,
        priceData,
        escrowContractData,
        listingsData,
        escrowBalanceData,
        claimBalanceData,
      ] = data;

      if (listingIdData) {
        setListingId(listingIdData.result);
      }

      if (priceData) {
        setSalePrice(ethers.formatEther(priceData.result));
      }

      if (escrowContractData) {
        setEscrowContractAddress(escrowContractData.result);
      }

      if (listingsData) {
        setListingInfo(listingsData.result);
      }

      if (escrowBalanceData) {
        setEscrowBalance(ethers.formatEther(escrowBalanceData.result));
      }
      if (claimBalanceData) {
        setClaimBalance(ethers.formatEther(claimBalanceData.result));
      }
    }
  }, [data]);

  // useEffect(() => {
  //   if (escrowContractAddress) {
  //     refetch()
  //   }
  // }, [modalData.claimNftModalOpen])
  

  return (
    <div className="w-full flex flex-col">
      {/* card header */}
      <div className="relative w-full max-h-[392px]">
        <a
          href={`https://testnet.bscscan.com/address/${escrowContractAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={metaData.image}
            alt={`${name} ${metaData.name}`}
            className="w-full h-[auto] max-h-[392px] cursor-pointer"
          />
        </a>
        {showMetadta && (
          <div className="absolute top-0 w-full h-full bg-black/70 pt-11 pb-6 px-4 max-h-[392px] overflow-hidden">
            <div className="w-full flex flex-col gap-y-3 text-white text-xs md:text-base">
              {attributes.map((attribute, index) => {
                const key = Object.keys(attribute)[0];
                return (
                  <div key={index} className="w-full flex flex-col">
                    <span className="opacity-50">{key}</span>
                    <div className="w-full flex flex-row items-center justify-between">
                      <span>{attribute[key]}</span>
                      <span>{attribute.value}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <button onClick={toggleShowMetadata} className="absolute top-2 right-2">
          <img src="/videos/nfts/info.png" alt="info" />
        </button>
      </div>
      {/* card content */}
      <div className="bg-[#BE9651] text-white  w-full pt-6 pb-8 px-6 flex flex-col gap-6">
        {/* details */}
        <div className="w-full flex flex-col gap-4">
          {/* title */}
          <h4 className="text-base md:text-xl leading-[30px]"> {metaData.name}</h4>
          {/* price */}
          {!isGalleryPage && (
            <div className="flex flex-col gap-2">
              <span className="opacity-50 text-sm md:text-base leading-6">Price:</span>
              <h4 className="text-base md:text-xl leading-[30px]">
                {salePrice
                  ? `${formatNumberToFixed(salePrice)} USDT`
                  : "Loading..."}
              </h4>
            </div>
          )}
          {/* claim balance */}
           
          <div className="flex flex-col gap-2">
            <span className="opacity-50 text-sm md:text-base leading-6">
              Escrow Balance:
            </span>
            <h4 className="text-base md:text-xl leading-[30px]">
              {escrowBalance
                ? `${formatNumberToFixed(escrowBalance)} BYAS`
                : "Loading..."}
            </h4>
          </div>
          {showClaimBalance && (
          <div className="flex flex-col gap-2">
            <span className="opacity-50 text-base leading-6">
              Claim Balance:
            </span>
            <h4 className="text-xl leading-[30px]">
              {claimBalance
                ? `${formatNumberToFixed(claimBalance)} BYAS`
                : "Loading..."}
            </h4>
          </div>
            )}
        </div>
        {/* button */}
        <div className="w-full flex flex-col gap-3">
          <button
            onClick={handlers[primaryBtnTitles[currentCardStatus].handler]}
            className={classNames(
              "min-h-12 w-full pt-0.5 px-6 flex items-center justify-center gap-2.5",
              {
                "bg-[#448327]": currentCardStatus === "buy",
                "bg-[#406DB1]": currentCardStatus === "edit",
                "bg-[#61B861]": currentCardStatus === "gallery",
              }
            )}
          >
            <span className="text-sm md:text-base leading-5">
              {primaryBtnTitles[currentCardStatus].title}
            </span>
          </button>
          {isGalleryPage && (
            <button
              onClick={handlers.handleListNft}
              className={classNames(
                "min-h-12 w-full pt-0.5 px-6 flex items-center justify-center gap-2.5",
                "bg-[#FCE87F]"
              )}
            >
              <span className="text-sm md:text-base leading-5">List</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NftCard;
