import { useEffect, useState } from "react";
import axios from "axios";
import { backendUrl } from "../components/utils";

const useGetNftsByWallet = ({ userAddress, limit = 50 }) => {
  const [data, setData] = useState([]); // Directly store the result array
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageInfo, setPageInfo] = useState({ page: null, cursor: null, pageSize: null }); // Additional info

  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

  useEffect(() => {
    const fetchNfts = async () => {
      setLoading(true);
      setError(null); // Reset error state on a new fetch

      try {
        const { data } = await axios.get(
          `${backendUrl}/api/moralis/user-nfts/${userAddress}?limit=${limit}&contractAddress=${contractAddress}`
        );
        setData(data.result ?? []); // Assume 'result' holds the NFT data array
        setPageInfo({ // Update page info based on the response
          page: data.page,
          cursor: data.cursor,
          pageSize: data.page_size,
        });
      } catch (error) {
        console.error("useGetNftsByWallet", error);
        setError(error);
        setData([]); // Reset data on error
      } finally {
        setLoading(false);
      }
    };

    if (userAddress) { // Only fetch if a user address is provided
      fetchNfts();
    } else {
      setData([]); // Clear data when there's no user address
      setPageInfo({ page: null, cursor: null, pageSize: null }); // Clear page info
    }
  }, [userAddress, limit, contractAddress]);

  return {
    data,
    loading,
    error,
    ...pageInfo, // Spread page info into the returned object
  };
};

export default useGetNftsByWallet;

