// imports
import { ethers } from "ethers";

// helpers
import { formatNumberToFixed } from "../../../helpers";

const TokensList = ({ balance, balances, erc20Tokens, onSelectToken }) => {
  return (
    <div className="max-w-lg w-full mt-4 flex flex-col gap-y-2">
      {erc20Tokens.map((token, index) => (
        <button
          key={index}
          type="button"
          onClick={() =>
            onSelectToken({
              address: token.address,
              name: token.name,
              symbol: token.symbol,
              balance: balances?.at(index)?.result ?? 0n,
              isNative: false,
            })
          }
          className="flex items-center justify-between w-full h-12 px-4 py-2 text-sm font-medium text-white bg-[#A3834E] hover:bg-white hover:text-[#9B8D47] focus:outline-none"
        >
          {/* symbol */}
          <span>{token.symbol}</span>
          {/* balance */}
          <span className="text-opacity-60 opacity-60">
            {formatNumberToFixed(
              ethers.formatEther(balances?.at(index)?.result ?? 0n)
            )}
          </span>
        </button>
      ))}
      <button
        type="button"
        onClick={() =>
          onSelectToken({
            address: null,
            name: "BNB",
            symbol: "BNB",
            balance: balance?.value ?? 0n,
            isNative: true,
          })
        }
        className="flex items-center justify-between w-full h-12 px-4 py-2 text-sm font-medium text-white bg-[#A3834E] hover:bg-white hover:text-[#9B8D47] focus:outline-none"
      >
        <span>BNB</span>
        <span className="text-opacity-60 opacity-60">
          {formatNumberToFixed(balance?.formatted)}
        </span>
      </button>
    </div>
  );
};

export default TokensList;
